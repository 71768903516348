import React, { memo } from "react"

const Telegram = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="65" height="64" fill="none" viewBox="0 0 65 64">
		<rect width="64" height="64" x="0.667" fill="#27A6E6" rx="32" />
		<path
			fill="#fff"
			fillRule="evenodd"
			d="M19.123 30.214c8.32-3.658 13.858-6.088 16.64-7.268 7.915-3.344 9.579-3.922 10.649-3.946.237 0 .76.048 1.117.337.285.24.356.553.404.794.047.24.095.746.047 1.131-.427 4.572-2.282 15.665-3.232 20.767-.404 2.165-1.189 2.887-1.95 2.96-1.664.144-2.923-1.107-4.516-2.166-2.52-1.66-3.922-2.695-6.37-4.332-2.83-1.877-.999-2.911.617-4.596.428-.433 7.726-7.17 7.868-7.772.024-.072.024-.361-.142-.506-.166-.144-.404-.096-.594-.048-.262.049-4.255 2.744-12.028 8.062-1.141.794-2.163 1.179-3.09 1.155-1.023-.024-2.972-.578-4.446-1.06-1.783-.577-3.209-.89-3.09-1.9.071-.53.784-1.059 2.116-1.612z"
			clipRule="evenodd"
		/>
	</svg>
)

export default memo(Telegram)
