import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"

import { useMst } from "models/Root"
import messages from "messages/common"
import OrderList from "./List"
import ListHeader from "./ListHeader"
import Filter from "./Filter"
import CurrentPrice from "./CurrentPrice"
import styles from "./index.module.scss"

const Orders: React.FC = () => {
	const { formatMessage } = useIntl()
	const { sellList, buyList } = useMst().terminal
	const [currentListType, setCurrentListType] = useState("AllOrders")

	return (
		<section className={styles.orders}>
			<h2 className="visually-hidden">{formatMessage(messages.orderbook)}</h2>

			<div className={styles.orders__header}>
				<button className={styles.orders__headerTabButton} type="button">
					{formatMessage(messages.orderbook)}
				</button>
			</div>

			{/*
					The positions of the elements are crucial,
					as they are styled in a hierarchical order.
					If you change the HTML, also adjust the CSS.
			*/}
			<div className={styles.orders__content}>
				<Filter setCurrentListType={setCurrentListType} currentListType={currentListType} />

				<div className={styles.orders__container}>
					<ListHeader />

					<div className={styles.orders__lists}>
						<OrderList isSell list={sellList} currentListType={currentListType} />

						<CurrentPrice />

						<ListHeader isBuy />

						<OrderList list={buyList} currentListType={currentListType} />
					</div>
				</div>
			</div>
		</section>
	)
}

export default observer(Orders)
