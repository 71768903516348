import {
	types as t,
	flow,
	getSnapshot,
	applySnapshot,
	Instance,
	cast,
	getParent,
} from "mobx-state-tree"

import { TerminalMobileWidgetEnum, IOrderBookData, TradeActionsEnum } from "types/exchange"
import { queryVars } from "constants/query"
import AccountService from "services/AccountService"
import { formatOrders, getPrecisionMap } from "helpers/exchange"
import ExchangeService from "services/ExchangeService"
import { getPrecision } from "utils/format"
import cache from "helpers/cache"
import { TERMINAL_LATEST_PAIR_CACHE_KEY } from "utils/cacheKeys"
import errorHandler from "utils/errorHandler"
import { FormatNumberFn } from "types/intlTypes"
import { MAX_PRICE_PRECISION } from "utils/constants"
import { Ticker, ITicker } from "models/Ticker"
import { Pair, IPair } from "./pair"
import { TradeForm } from "./tradeForm"
import { MarginCurrency } from "./marginCurrency"
import { LoanConditions } from "./loanConditions"
import { trades, IRecentTrade } from "./recentTrades"
import { orderBook } from "./orderBook"
import { IRootStore } from "../Root"

export const Terminal = t
	.model({
		ticker: t.maybe(t.reference(Ticker)),
		pair: t.optional(t.maybeNull(Pair), null),
		displayChartOrders: t.optional(t.boolean, true),
		isTickersAbsolute: t.optional(t.boolean, false),
		isTickersExpanded: t.optional(t.boolean, true),
		isQuickOrderPlacementOpen: t.optional(t.boolean, false),
		isLoading: t.optional(t.boolean, false),
		isLoaded: t.optional(t.boolean, false),
		isMarginAccepted: t.optional(t.boolean, true),
		tradeForm: t.optional(TradeForm, {
			clickedOrder: null,
			orderPrice: "",
			orderQty: "",
			orderValue: "",
			triggerPrice: "",
			rangePercentage: 0,
			order: null,
			isOpenStopOrder: false,
			isOpenMarginChangeModal: false,
			marginLvl: 2,
		}),
		showAllOpenedOrders: t.optional(t.boolean, false),
		isOpenSearch: t.optional(t.boolean, false),
		marginCurrency: t.optional(MarginCurrency, {}),
		loanConditions: t.optional(t.array(LoanConditions), []),
		mobileActiveWidget: t.optional(t.string, TerminalMobileWidgetEnum.TRADE),
		chartSubscribeSymbol: t.optional(t.string, ""),
		orderBookPrecision: t.optional(t.number, MAX_PRICE_PRECISION),
	})
	.actions((self: any) => {
		const initialState = getSnapshot(self)
		return {
			resetState() {
				applySnapshot(self, initialState)
			},
		}
	})
	.views((self: any) => ({
		get pairSymbol() {
			return self.pair?.symbol ?? ""
		},
	}))
	.views((self: any) => ({
		get recentTrades() {
			return trades.list.filter((t: any) => t.symbol === self.pairSymbol)
		},
		get currentPrice() {
			return self.pair ? self.pair.close : 0
		},
		get baseCurrencyCode() {
			return self.pair?.base_currency_code || ""
		},
		get baseCurrencyName() {
			return self.pair?.base_currency_name || ""
		},
		get quoteCurrencyCode() {
			return self.pair?.quote_currency_code || ""
		},
		get pairAmountPrecision() {
			return self.pair?.amount_precision !== undefined ? self.pair?.amount_precision : 8
		},
		get pairLabel() {
			return self.pair?.label || ""
		},
		get pairPricePrecision() {
			return self.pair?.price_precision !== undefined ? self.pair?.price_precision : 8
		},
		get marginLeverage() {
			return self.ticker?.cross_margin_leverage
		},
		get maxMarginLeverage() {
			return self.ticker?.cross_margin_leverage
		},
		get currentCurrencyInterestRate() {
			const pair: any = getParent<IRootStore>(self).terminal.pair

			return (
				self.loanConditions?.find((item: any) => item?.currency?.code === pair?.quote_currency_code)
					?.interest_rate ?? 0
			)
		},
	}))
	.views((self: any) => ({
		get recentTradeDiff() {
			return self.recentTrades.length > 1
				? (self.recentTrades[0]?.price ?? 0) - (self.recentTrades[1]?.price ?? 0)
				: 0
		},
		get recentTrade() {
			return self.recentTrades.length > 0 ? self.recentTrades[0] : null
		},
		get precisionArray() {
			return self.pair
				? Array.from(getPrecisionMap(self.pair.price_precision, self.pair.close).entries())
				: []
		},
		get sellList() {
			const openedOrdersSellKeys = getParent<IRootStore>(self).history.openedOrdersSellKeys

			return orderBook.getList(true, self.orderBookPrecision, openedOrdersSellKeys)
		},
		get buyList() {
			const openedOrdersBuyKeys = getParent<IRootStore>(self).history.openedOrdersBuyKeys

			return orderBook.getList(false, self.orderBookPrecision, openedOrdersBuyKeys)
		},
		getPriceString(formatNumber: FormatNumberFn) {
			return formatNumber(self.pair?.close ? self.pair.close : 0, {
				useGrouping: true,
				maximumFractionDigits: self.pairPricePrecision,
				minimumFractionDigits: self.pairPricePrecision,
			})
		},
	}))
	.actions((self: any) => ({
		getCurrencyCodeBySide(side: TradeActionsEnum) {
			return side === TradeActionsEnum.BUY ? self.quoteCurrencyCode : self.baseCurrencyCode
		},
	}))
	.actions((self: any) => ({
		setTicker(nextTicker: ITicker) {
			self.ticker = nextTicker
		},
		handleOpenSearch(bool: boolean) {
			self.isOpenSearch = bool
		},
		setOrderBookPrecision(v: number) {
			self.orderBookPrecision = v
		},
		toggleDisplayChartOrders() {
			self.displayChartOrders = !self.displayChartOrders
		},
		setIsLoaded(nextIsLoaded: boolean) {
			self.isLoaded = nextIsLoaded
		},
		setShowAllOpenedOrders(nextShowAllOpenedOrders: boolean) {
			self.showAllOpenedOrders = nextShowAllOpenedOrders
		},
		setIsQuickOrderPlacementOpen(nextIsOpen: boolean) {
			self.isQuickOrderPlacementOpen = nextIsOpen
		},
		setChartSubscribeSymbol(nextSymbol: string) {
			self.chartSubscribeSymbol = nextSymbol
		},
		addRecentTrade(trade: IRecentTrade) {
			trades.add(trade)
		},
		updatePair(nextPair: IPair) {
			self.pair = cast(nextPair)
		},
		setIsTickersExpanded(nextIsTickersExpanded: boolean) {
			self.isTickersExpanded = nextIsTickersExpanded
		},
		setIsTickersAbsolute(nextisTickersAbsolute: boolean) {
			self.isTickersExpanded = true
			self.isTickersAbsolute = nextisTickersAbsolute
		},
		setMobileActiveWidget(nextActiveWidget: TerminalMobileWidgetEnum) {
			self.mobileActiveWidget = nextActiveWidget
		},
		updateOrderBook(data: IOrderBookData) {
			orderBook.update(
				formatOrders(data.bids, self.ticker?.price_precision ?? 8),
				formatOrders(data.asks, self.ticker?.price_precision ?? 8),
			)
		},
	}))
	.actions((self: any) => ({
		loadExchange: flow(function* (isAuthenticated?: boolean) {
			if (self.ticker) {
				try {
					self.isLoading = true
					// @ts-ignore
					const data = yield ExchangeService.getExchange({
						pair: self.ticker.symbol,
						coin_info: true,
						recent_trades: true,
						v2: true,
						wallets: isAuthenticated,
						depth: true,
					})

					if (!data) {
						return
					}

					const { depth, pair, recent_trades } = data

					if (Array.isArray(recent_trades)) {
						const defaultSymbol = self.ticker?.symbol ?? ""

						const updatedTrades = recent_trades.map((t: IRecentTrade) => ({
							...t,
							amount: t.amount ?? 0,
							date: t.date ?? 0,
							price: t.price ?? 0,
							symbol: t.symbol ?? defaultSymbol,
							type: t.type ?? 0,
						}))

						trades.init(updatedTrades)
					}

					if (data.pair && typeof data.pair === "object") {
						data.pair.close = self.ticker.close
						data.pair.low = self.ticker.low
						data.pair.high = self.ticker.high
						data.pair.base_volume = self.ticker.base_volume
						data.pair.change = self.ticker.change
						data.pair.change_percent = self.ticker.change_percent
						self.pair = cast({
							...self.ticker,
							...data.pair,
							base_currency_name: self.ticker.base_currency.name,
							quote_currency_name: self.ticker.quote_currency.name,
						})
						self.setOrderBookPrecision(getPrecision(data.pair.price_precision))
						cache.setItem(TERMINAL_LATEST_PAIR_CACHE_KEY, data.pair.symbol)
					}

					const { bids, asks } = depth || {}

					if (bids && asks) {
						const minOrderAmount = pair?.amount_precision ? 10 ** -pair.amount_precision : 0

						const filterOrders = (orders: string[]) => orders.filter(o => +o[1] >= minOrderAmount)

						// @ts-ignore
						const formattedBids = formatOrders(filterOrders(bids), pair.price_precision)
						// @ts-ignore
						const formattedAsks = formatOrders(filterOrders(asks), pair.price_precision)

						orderBook.update(formattedBids, formattedAsks)
					}
				} catch (err) {
					console.error(err)
				} finally {
					self.isLoading = false
					self.isLoaded = true
				}
			}
		}),
		loadMarginCurrency: flow(function* (accountType: number, pair?: string) {
			const baseCurrencyCode: any = getParent<IRootStore>(self).terminal.pair?.baseCurrencyCode
			const quoteCurrencyCode: any = getParent<IRootStore>(self).terminal.pair?.quoteCurrencyCode

			if (!baseCurrencyCode && !quoteCurrencyCode) {
				return
			}

			try {
				// @ts-ignore
				const baseStatus = yield ExchangeService.getCurrencyStatus({
					[queryVars.wallet_type]: accountType,
					currency: baseCurrencyCode,
					pair: pair,
				})
				// @ts-ignore
				const quoteStatus = yield ExchangeService.getCurrencyStatus({
					[queryVars.wallet_type]: accountType,
					currency: quoteCurrencyCode,
					pair: pair,
				})

				if (baseStatus && quoteStatus) {
					self.marginCurrency = cast({ base: baseStatus, quote: quoteStatus })
				}
			} catch (err) {
				errorHandler(err)
			}
		}),
		loadMarginRiskStatus: flow(function* () {
			try {
				const { is_margin_accepted } = yield AccountService.getProfileStatus()

				if (self.isMarginAccepted !== is_margin_accepted) {
					self.isMarginAccepted = is_margin_accepted
				}
			} catch (err) {
				errorHandler(err)
			}
		}),
		setMarginRiskStatus: flow(function* () {
			try {
				const status = yield ExchangeService.acceptMarginTerms()

				self.isMarginAccepted = true
			} catch (err) {
				errorHandler(err)
			}
		}),
		loadLoanConditions: flow(function* () {
			try {
				// @ts-ignore
				const loanConditions = yield ExchangeService.getLoanConditions()
				if (loanConditions) {
					self.loanConditions = cast(loanConditions)
				}
			} catch (err) {
				errorHandler(err)
			}
		}),
		loadLastMarginLvl: flow(function* () {
			try {
				// @ts-ignore
				const res = yield ExchangeService.getLastMarginLeverage()
				if (res) {
					self.tradeForm.setMarginLvl(res.leverage !== 0 ? res.leverage : 3)
				}
			} catch (err) {
				errorHandler(err)
			}
		}),
		loadOrderBook: flow(function* () {
			try {
				// @ts-ignore
				const data = yield ExchangeService.getExchange({
					pair: self.ticker.symbol,
					v2: true,
					depth: true,
				})
				const { depth, pair } = data

				const { bids, asks } = depth || {}

				if (bids && asks) {
					const minOrderAmount = pair?.amount_precision ? 10 ** -pair.amount_precision : 0

					const filterOrders = (orders: string[]) => orders.filter(o => +o[1] >= minOrderAmount)

					// @ts-ignore
					const formattedBids = formatOrders(filterOrders(bids), pair.price_precision)
					// @ts-ignore
					const formattedAsks = formatOrders(filterOrders(asks), pair.price_precision)

					orderBook.update(formattedBids, formattedAsks)
				}
			} catch (err) {
				errorHandler(err)
			}
		}),
	}))

export interface ITerminal extends Instance<typeof Terminal> {}
