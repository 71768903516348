import React, { memo } from "react"

const TriangleIcon = () => (
	<svg
		width="188"
		height="199"
		viewBox="0 0 188 199"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M0.195817 8.6287C-0.651283 3.3071 4.58009 -0.935918 9.61228 0.991273L183.338 67.5237C188.371 69.4509 189.43 76.1029 185.244 79.4973L40.7626 196.682C36.5775 200.077 30.2872 197.668 29.4401 192.346L0.195817 8.6287Z"
			fill="#5A65FF"
			fillOpacity="0.2"
		/>
		<path
			d="M0.689602 8.5501C-0.0969926 3.60861 4.76071 -0.331332 9.43346 1.4582L183.16 67.9906C187.832 69.7802 188.816 75.957 184.929 79.109L40.4476 196.294C36.5614 199.446 30.7205 197.209 29.9339 192.268L0.689602 8.5501Z"
			stroke="#5A65FF"
			strokeOpacity="0.2"
		/>
	</svg>
)

export default memo(TriangleIcon)
