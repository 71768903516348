import React from "react"
import Cookies from "components/Cookies"
import Header from "../components/NewUI/Header"
import Footer from "../components/NewUI/Footer"

interface IProps {
	mobile?: boolean
	children: any
}

const TerminalLayout: React.FC<IProps> = ({ children, mobile }) => (
	<>
		<Header />
		<div className="layout">{children}</div>
		<Footer />
		<Cookies />
	</>
)

export default TerminalLayout
