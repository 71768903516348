import React, { memo } from "react"

const TradingVolume = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" fill="none" viewBox="0 0 31 30">
		<path
			fill="#B2B7C6"
			d="M7 23.333h3.333V15H7v8.333zm13.333 0h3.334V6.667h-3.334v16.666zm-6.666 0H17v-5h-3.333v5zm0-8.333H17v-3.333h-3.333V15zm-10 15a3.211 3.211 0 01-2.355-.98c-.654-.653-.98-1.438-.979-2.353V3.333c0-.916.327-1.701.98-2.355C1.967.325 2.751-.002 3.667 0H27c.917 0 1.702.327 2.355.98s.98 1.438.978 2.353v23.334c0 .916-.326 1.701-.98 2.355-.653.653-1.437.98-2.353.978H3.667z"
		/>
	</svg>
)

export default memo(TradingVolume)
