import React, { ChangeEvent as CE, MouseEvent as ME, useCallback } from "react"
import { useIntl } from "react-intl"
import { observer } from "mobx-react-lite"
import Skeleton from "react-loading-skeleton"

import Input from "components/NewUI/Input"
import messages from "messages/exchange"
import commonMessages from "messages/common"
import { TradeActionsEnum } from "types/exchange"
import FormBottomArrow from "assets/icons/terminal/FormBottomArrow"
import FormTopArrow from "assets/icons/terminal/FormTopArrow"
import styles from "./index.module.scss"

const list = [
	{
		percent: 0.5,
	},
	{
		percent: 1,
	},
	{
		percent: 5,
	},
]

const OrderPriceQty: React.FC<{
	tradeAction: TradeActionsEnum
	changeOrderQty: (value: string) => void
	changeOrderPrice: (value: string) => void
	blurOrderPrice: (value: string) => void
	blurOrderQty: (value: string) => void
	quoteCurrencyCode: string
	price: string
	baseCurrencyCode: string
	amount: string
	recentTradeAction: (percent: number) => void
	bidTradeAction: () => void
	askTradeAction: () => void
	handleSubmit?: () => Promise<void>
	orderPriceError: string
	qtyError: string
	isLoaded?: boolean
}> = ({
	tradeAction,
	quoteCurrencyCode,
	price,
	recentTradeAction,
	bidTradeAction,
	askTradeAction,
	baseCurrencyCode,
	amount,
	handleSubmit,
	changeOrderQty,
	changeOrderPrice,
	blurOrderPrice,
	blurOrderQty,
	orderPriceError,
	qtyError,
	isLoaded,
}) => {
	const { formatMessage } = useIntl()
	const onChangeOrderPrice = (ev: CE<HTMLInputElement>) => changeOrderPrice(ev.target.value)
	const onBlurOrderPrice = (ev: CE<HTMLInputElement>) => blurOrderPrice(ev.target.value)
	const onChangeOrderQty = (ev: CE<HTMLInputElement>) => changeOrderQty(ev.target.value)
	const onBlurOrderQty = (ev: CE<HTMLInputElement>) => blurOrderQty(ev.target.value)

	const percentAction = useCallback(
		(ev: ME<HTMLButtonElement>, percent: number) => {
			ev.preventDefault()

			recentTradeAction(percent)
		},
		[recentTradeAction],
	)

	return (
		<div className={styles.orderPriceQty}>
			<label htmlFor={`${tradeAction}Amount1`} className={styles.orderPriceQty__price}>
				<span className={styles.orderPriceQty__labelText}>
					{formatMessage(messages.order_price)}
				</span>

				{isLoaded ? (
					<Input
						id={`${tradeAction}Amount1`}
						name={`${tradeAction}-price`}
						type="text"
						postfixText={quoteCurrencyCode}
						placeholder={Number(price) === 0 ? "0" : ""}
						value={price}
						onChange={onChangeOrderPrice}
						onBlur={onBlurOrderPrice}
						onEnter={handleSubmit}
						error={orderPriceError}
					/>
				) : (
					<Skeleton height={40} borderRadius={8} />
				)}
			</label>

			<ul className={styles.orderPriceQty__buttons}>
				<li className={styles.orderPriceQty__buttonItem}>
					<button onClick={bidTradeAction} className={styles.orderPriceQty__button} type="button">
						BID
					</button>
				</li>

				<li className={styles.orderPriceQty__buttonItem}>
					<button onClick={askTradeAction} className={styles.orderPriceQty__button} type="button">
						ASK
					</button>
				</li>

				{list.map((item, index) => (
					<li key={item.percent} className={styles.orderPriceQty__buttonItem}>
						<button
							onClick={ev => percentAction(ev, item.percent)}
							className={styles.orderPriceQty__button}
							type="button"
						>
							<span>{item.percent}%</span>{" "}
							{tradeAction === TradeActionsEnum.SELL ? <FormBottomArrow /> : <FormTopArrow />}
						</button>
					</li>
				))}
			</ul>

			<label htmlFor={`${tradeAction}Price`} className={styles.orderPriceQty__qty}>
				<span className={styles.orderPriceQty__labelText}>
					{formatMessage(commonMessages.quantity)}
				</span>

				{isLoaded ? (
					<Input
						id={`${tradeAction}Price`}
						name={`${tradeAction}-amount`}
						type="text"
						postfixText={baseCurrencyCode}
						placeholder={Number(amount) === 0 ? "0" : ""}
						value={amount}
						onChange={onChangeOrderQty}
						onBlur={onBlurOrderQty}
						onEnter={handleSubmit}
						error={qtyError}
					/>
				) : (
					<Skeleton height={40} borderRadius={8} />
				)}
			</label>
		</div>
	)
}

export default observer(OrderPriceQty)
