import React, { FC } from "react"
import Skeleton from "react-loading-skeleton"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"
import cn from "classnames"

import { useMst } from "models/Root"
import styles from "../index.module.scss"

const CurrentPrice: FC = () => {
	const { formatNumber } = useIntl()
	const { recentTradeDiff, quoteCurrencyCode, isLoaded, getPriceString } = useMst().terminal
	const currentPrice = getPriceString(formatNumber)

	return (
		<div className={styles.orders__currentPrice}>
			<span
				className={cn({
					[styles.orders__currentPriceText]: true,
					[styles.orders__currentPriceText___green]: recentTradeDiff > 0,
					[styles.orders__currentPriceText___red]: recentTradeDiff < 0,
				})}
			>
				{isLoaded ? currentPrice : <Skeleton width={81} height={20} borderRadius={2} />}
			</span>

			{quoteCurrencyCode === "USDT" || quoteCurrencyCode === "USDC" ? (
				<span className={styles.orders__currentPriceText2}>
					{isLoaded ? (
						`≈ ${currentPrice} USD`
					) : (
						<Skeleton width={90} height={20} borderRadius={2} />
					)}
				</span>
			) : null}
		</div>
	)
}

export default observer(CurrentPrice)
