import React, { memo } from "react"

const XIcon = () => (
	<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="64" height="64" rx="32" fill="black" />
		<path
			d="M16.8134 17.7253L28.5777 33.4545L16.7393 46.2431H19.4038L29.7686 35.0463L38.1428 46.2431H47.2098L34.7833 29.6293L45.8026 17.7253H43.138L33.5929 28.0371L25.8804 17.7253H16.8134ZM20.7318 19.6878H24.8971L43.2909 44.2807H39.1256L20.7318 19.6878Z"
			fill="white"
		/>
	</svg>
)

export default memo(XIcon)
