import React, { memo } from "react"

const MobileProfile = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none" viewBox="0 0 36 36">
		<path
			stroke="#7C59FF"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="1.5"
			d="M18 28c5.523 0 10-4.477 10-10S23.523 8 18 8 8 12.477 8 18s4.477 10 10 10z"
		/>
		<path
			stroke="#7C59FF"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="1.5"
			d="M18 21.333A4.167 4.167 0 1018 13a4.167 4.167 0 000 8.333zM11.313 25.434a7.5 7.5 0 0113.375 0"
		/>
	</svg>
)

export default memo(MobileProfile)
