import React from "react"
import { observer } from "mobx-react-lite"
import Skeleton from "react-loading-skeleton"
import { useIntl } from "react-intl"

import messages from "messages/common"
import exchangeMessages from "messages/exchange"
import historyMessages from "messages/history"
import styles from "../index.module.scss"
import { useMst } from "../../../../../models/Root"

const ListHeader: React.FC<{
	isBuy?: boolean
}> = ({ isBuy = false }) => {
	const { formatMessage } = useIntl()
	const { baseCurrencyCode, quoteCurrencyCode } = useMst().terminal

	return (
		<div
			className={`${styles.orders__containerHeader} ${
				isBuy ? styles.orders__containerHeader___list : ""
			}`}
		>
			<span className={styles.orders__headerText}>
				{formatMessage(historyMessages.orders_table_price)} (
				{quoteCurrencyCode || <Skeleton width={26} height={11} borderRadius={2} />})
			</span>

			<span className={styles.orders__headerText}>
				{formatMessage(messages.quantity)} (
				{baseCurrencyCode || <Skeleton width={22} height={11} borderRadius={2} />})
			</span>

			<span className={styles.orders__headerText}>
				{formatMessage(exchangeMessages.total)} (
				{baseCurrencyCode || <Skeleton width={22} height={11} borderRadius={2} />})
			</span>
		</div>
	)
}

export default observer(ListHeader)
