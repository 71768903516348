import React, { memo } from "react"

const ProgressQ = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<circle cx="16" cy="16" r="15" fill="#202028" stroke="#7C59FF" strokeWidth="2" />
		<path
			d="M16 7C14.5199 7 13.0627 7.36501 11.7574 8.06271C10.4521 8.7604 9.33905 9.76924 8.51677 10.9999C7.6945 12.2305 7.18841 13.6449 7.04334 15.1178C6.89827 16.5908 7.11869 18.0768 7.68508 19.4442C8.25148 20.8115 9.14636 22.0182 10.2905 22.9571C11.4346 23.896 12.7926 24.5383 14.2442 24.8271C15.6958 25.1158 17.1962 25.0421 18.6126 24.6125C20.0289 24.1828 21.3174 23.4105 22.364 22.364L16 16V7Z"
			fill="#7C59FF"
		/>
	</svg>
)

export default memo(ProgressQ)
