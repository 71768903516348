import React from "react"
import { observer } from "mobx-react-lite"
import { useIntl } from "react-intl"
import dayjs from "dayjs"

import { routes } from "constants/routing"
import messages from "messages/common"
import support_messages from "messages/support"
import InternalLink from "components/InternalLink"
import feesMessages from "messages/fees_trading"
import config from "helpers/config"
import styles from "./index.module.scss"

const Footer: React.FC = () => {
	const { formatMessage } = useIntl()
	const currentYear = dayjs().year()

	return (
		<footer className={styles.footer}>
			<ul className={styles.footer__list}>
				<li className={styles.footer__item}>
					<InternalLink className={styles.footer__link} to="/#">
						{formatMessage(messages.home)}
					</InternalLink>
				</li>

				<li className={styles.footer__item}>
					<InternalLink className={styles.footer__link} to={routes.tradingFees}>
						{formatMessage(feesMessages.trading_fees)}
					</InternalLink>
				</li>

				<li className={styles.footer__item}>
					<a
						className={styles.footer__link}
						href={config.publicApiPath}
						target="_blank"
						rel="noopener noreferrer"
					>
						{formatMessage(messages.api)}
					</a>
				</li>

				<li className={styles.footer__item}>
					<InternalLink className={styles.footer__link} to={routes.support.root}>
						{formatMessage(support_messages.support_center)}
					</InternalLink>
				</li>

				<li className={styles.footer__item}>
					<InternalLink className={styles.footer__link} to={routes.privacyPolicy}>
						Privacy Policy
					</InternalLink>
				</li>
			</ul>

			<p className={styles.footer__copy}>© {currentYear} BTC-Alpha. All rights reserved.</p>
		</footer>
	)
}

export default observer(Footer)
