import { TableRow, TableData } from "components/UI/Table"
import React from "react"
import { FormatNumberOptions, useIntl } from "react-intl"
import { ITradingFeesTier } from "types/tradingFees"
import feesMessages from "messages/fees_trading"
import styles from "styles/pages/TradingFees.module.scss"
import classNames from "classnames"

const FORMAT_NUMBER_OPTIONS: FormatNumberOptions = {
	useGrouping: false,
	minimumFractionDigits: 4,
	maximumFractionDigits: 4,
}
interface IProps {
	tier: ITradingFeesTier
	isCurrentTier: boolean
}

const ActiveTier: React.FC = () => <div className={styles.fee_active_tier} />

const FeeSpotTableRow: React.FC<IProps> = ({ tier, isCurrentTier }) => {
	const { formatNumber } = useIntl()
	return (
		<TableRow
			className={classNames({
				[styles.fee_table_row_active]: isCurrentTier,
			})}
		>
			{isCurrentTier && <ActiveTier />}
			<TableData width="100px" maxWidth="100px" align="center">
				{tier.name}
			</TableData>
			<TableData width="100px" align="right">
				{`≥ ${tier.min_volume} USDT`}
			</TableData>
			<TableData disabled width="35px" align="center">
				or
			</TableData>
			<TableData width="100px">{`≥ ${tier.min_equity} USDT`}</TableData>
			<TableData width="45px" />
			<TableData half width="90px">
				{formatNumber((tier.maker_fee_rate ?? 0) * 100, FORMAT_NUMBER_OPTIONS)}%
			</TableData>
			<TableData half width="90px">
				{formatNumber((tier.taker_fee_rate ?? 0) * 100, FORMAT_NUMBER_OPTIONS)}%
			</TableData>
		</TableRow>
	)
}

export const InfoRow: React.FC = () => {
	const { formatMessage } = useIntl()

	return (
		<TableRow>
			<TableData width="100px" maxWidth="100px" />
			<TableData disabled width="100px" align="right">
				{formatMessage(feesMessages.table_days_30)}
			</TableData>
			<TableData disabled width="35px" align="center" />
			<TableData disabled width="100px">
				Right now
			</TableData>
			<TableData width="45px" />
			<TableData disabled half width="90px">
				Maker
			</TableData>
			<TableData disabled half width="90px">
				Taker
			</TableData>
		</TableRow>
	)
}

export default FeeSpotTableRow
