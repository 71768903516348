import React, { useCallback, useMemo } from "react"
import { observer } from "mobx-react-lite"
import { Range as ReactRange } from "react-range"

import styles from "./index.module.scss"

type renderTrackTypes = {
	props: {
		style: React.CSSProperties
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		ref: React.RefObject<any>
		onMouseDown: (e: React.MouseEvent) => void
		onTouchStart: (e: React.TouchEvent) => void
	}
	children: React.ReactNode
}

type renderThumbTypes = {
	props: {
		key: number
		style: React.CSSProperties
		tabIndex?: number
		"aria-valuemax": number
		"aria-valuemin": number
		"aria-valuenow": number
		draggable: boolean
		role: string
		onKeyDown: (e: React.KeyboardEvent) => void
		onKeyUp: (e: React.KeyboardEvent) => void
	}
}

const Range: React.FC<{
	step?: number
	min?: number
	max?: number
	values: number[]
	setValues: (val: number[]) => void
	disabled?: boolean
	isPercent?: boolean
	isDarkerStyle?: boolean
}> = ({
	step = 1,
	min = 0,
	max = 100,
	values = [0],
	setValues,
	disabled = false,
	isPercent = true,
	isDarkerStyle = false,
}) => {
	// We cache the values for optimization.
	const currentValue = useMemo(() => values[0], [values])
	const currentPercentValue = useMemo(
		() => ((currentValue - min) / (max - min)) * 100,
		[currentValue, min, max],
	)

	// Change function
	const onChange = (val: number[]) => setValues(val)

	// Set the value to 0%
	const set0 = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			event.stopPropagation()

			onChange([min])
		},
		[min, onChange],
	)
	// Set the value to 25%
	const set25 = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			event.stopPropagation()

			onChange([max * 0.25])
		},
		[max, onChange],
	)
	// Set the value to 50%
	const set50 = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			event.stopPropagation()

			onChange([max * 0.5])
		},
		[max, onChange],
	)
	// Set the value to 75%
	const set75 = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			event.stopPropagation()

			onChange([max * 0.75])
		},
		[max, onChange],
	)
	// Set the value to 100%
	const set100 = useCallback(
		(event: React.MouseEvent<HTMLButtonElement>) => {
			event.stopPropagation()

			onChange([max])
		},
		[max, onChange],
	)

	// Function that renders the bar, we use useCallback for optimization.
	const renderTrack = useCallback(
		({ props, children }: renderTrackTypes) => (
			<div
				ref={props.ref}
				className={`${styles.range__track} ${disabled ? styles.range__track___disabled : ""}`}
			>
				<div className={styles.range__trackList}>
					<div
						onMouseDown={props.onMouseDown}
						onTouchStart={props.onTouchStart}
						className={styles.range__trackListRange}
					>
						<div
							className={styles.range__trackListRangeInner}
							style={{ width: `${currentPercentValue}%` }}
						/>
					</div>

					{/* Button that sets the value to 0 percent */}
					<button
						onClick={set0}
						className={`${styles.range__trackButton} ${
							currentPercentValue >= 0 ? styles.range__trackButton___checked : ""
						}`}
						type="button"
						aria-label="0%"
					/>

					{isPercent ? (
						<>
							{/* Button that sets the value to 25 percent */}
							<button
								onClick={set25}
								className={`${styles.range__trackButton} ${
									currentPercentValue >= 25 ? styles.range__trackButton___checked : ""
								}`}
								type="button"
								aria-label="25%"
							/>

							{/* Button that sets the value to 50 percent */}
							<button
								onClick={set50}
								className={`${styles.range__trackButton} ${
									currentPercentValue >= 50 ? styles.range__trackButton___checked : ""
								}`}
								type="button"
								aria-label="50%"
							/>

							{/* Button that sets the value to 75 percent */}
							<button
								onClick={set75}
								className={`${styles.range__trackButton} ${
									currentPercentValue >= 75 ? styles.range__trackButton___checked : ""
								}`}
								type="button"
								aria-label="75%"
							/>
						</>
					) : null}

					{/* Button that sets the value to 100 percent */}
					<button
						onClick={set100}
						className={`${styles.range__trackButton} ${
							currentPercentValue >= 100 ? styles.range__trackButton___checked : ""
						}`}
						type="button"
						aria-label="100%"
					/>
				</div>

				{children}
			</div>
		),
		[currentValue, disabled, set0, set25, set50, set75, set100],
	)

	// Function that renders the dot, we use useCallback for optimization.
	const renderThumb = useCallback(
		({ props }: renderThumbTypes) => (
			<button type="button" {...props} key={props.key} className={styles.range__thumbWrapper}>
				<p className={styles.range__thumbText}>
					{Math.round(currentValue)}
					{isPercent ? "%" : ""}
				</p>

				<div aria-label="Press to move" className={styles.range__thumb} />
			</button>
		),
		[currentValue],
	)

	return (
		<div className={`${styles.range} ${isDarkerStyle ? styles.range___darker : ""}`}>
			{/* Lib docs https://github.com/tajo/react-range */}
			<ReactRange
				step={step}
				min={min}
				max={max}
				values={values}
				onChange={onChange}
				renderTrack={renderTrack}
				renderThumb={renderThumb}
			/>

			<div className={styles.range__percents}>
				<span className={styles.range__percentText}>
					{min}
					{isPercent ? "%" : ""}
				</span>

				<span className={styles.range__percentText}>
					{max}
					{isPercent ? "%" : ""}
				</span>
			</div>
		</div>
	)
}

export default observer(Range)
