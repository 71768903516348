import React, { memo } from "react"

const Menu = () => (
	<svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10 5a2 2 0 114 0 2 2 0 01-4 0zM10 12a2 2 0 114 0 2 2 0 01-4 0zM10 19a2 2 0 114 0 2 2 0 01-4 0zM17 5a2 2 0 114 0 2 2 0 01-4 0zM17 12a2 2 0 114 0 2 2 0 01-4 0zM17 19a2 2 0 114 0 2 2 0 01-4 0zM3 5a2 2 0 114 0 2 2 0 01-4 0zM3 12a2 2 0 114 0 2 2 0 01-4 0zM3 19a2 2 0 114 0 2 2 0 01-4 0z"
			fill="#7c59ff"
		/>
	</svg>
)

export default memo(Menu)
