import React, { memo } from "react"

const RightControl = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="28" height="14" fill="none" viewBox="0 0 28 14">
		<path
			stroke="#7C59FF"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="1.5"
			d="M1 7h26M21 1l6 6-6 6"
		/>
	</svg>
)

export default memo(RightControl)
