import React, { memo } from "react"

const LeftControl = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="28" height="14" fill="none" viewBox="0 0 28 14">
		<path
			stroke="#7C59FF"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="1.5"
			d="M27 7H1M7 13L1 7l6-6"
		/>
	</svg>
)

export default memo(LeftControl)
