import React, { memo } from "react"

const Visa = () => (
	<svg width="110" height="106" viewBox="0 0 110 106" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_1_121712)">
			<path
				d="M67.3511 39.0435C61.3221 39.0435 55.9339 42.2522 55.9339 48.1793C55.9339 54.9772 65.4896 55.4467 65.4896 58.8623C65.4896 60.3004 63.8848 61.5876 61.1427 61.5876C57.2523 61.5876 54.3444 59.7891 54.3444 59.7891L53.1 65.7711C53.1 65.7711 56.4499 67.2904 60.8971 67.2904C67.4888 67.2904 72.6764 63.9246 72.6764 57.8954C72.6764 50.7117 63.0807 50.2562 63.0807 47.0867C63.0807 45.9601 64.3982 44.7262 67.1318 44.7262C70.2165 44.7262 72.7325 46.0343 72.7325 46.0343L73.9505 40.2573C73.9505 40.2573 71.2118 39.0435 67.3503 39.0435H67.3511ZM12.1887 39.4798L12.0425 40.3516C12.0425 40.3516 14.5789 40.828 16.8637 41.7792C19.8055 42.8692 20.0146 43.5045 20.5102 45.4749L25.9085 66.8401H33.1454L44.294 39.4798H37.0741L29.9103 58.0822L26.9872 42.3133C26.7194 40.5086 25.3611 39.4789 23.6994 39.4789H12.1895L12.1887 39.4798ZM47.1976 39.4798L41.5341 66.8401H48.4191L54.0622 39.4798H47.1976ZM85.5972 39.4798C83.9372 39.4798 83.0574 40.3926 82.4123 41.9869L72.3253 66.8401H79.5452L80.9418 62.6976H89.7376L90.5867 66.8401H96.9575L91.4002 39.4798H85.5972ZM86.5365 46.872L88.6768 57.1388H82.9435L86.5373 46.872H86.5365Z"
				fill="#8B909E" />
		</g>
		<defs>
			<clipPath id="clip0_1_121712">
				<rect width="85" height="28.3333" fill="white" transform="translate(12 39)" />
			</clipPath>
		</defs>
	</svg>
)

export default memo(Visa)
