import React, { memo } from "react"

const Linkedin = () => (
	<svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="0.333252" width="64" height="64" rx="32" fill="#007EBB" />
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M47.9998 47H41.4248V35.8013C41.4248 32.7309 40.2581 31.0151 37.8279 31.0151C35.1841 31.0151 33.8029 32.8007 33.8029 35.8013V47H27.4664V25.6667H33.8029V28.5403C33.8029 28.5403 35.7081 25.0149 40.2352 25.0149C44.7602 25.0149 47.9998 27.7781 47.9998 33.4931V47ZM19.907 22.8732C17.7487 22.8732 15.9998 21.1106 15.9998 18.9366C15.9998 16.7627 17.7487 15 19.907 15C22.0654 15 23.8133 16.7627 23.8133 18.9366C23.8133 21.1106 22.0654 22.8732 19.907 22.8732ZM16.6352 47H23.2425V25.6667H16.6352V47Z"
			fill="white"
		/>
	</svg>
)

export default memo(Linkedin)
