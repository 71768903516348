import React, { memo } from "react"

const CrossBurger = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none" viewBox="0 0 36 36">
		<path
			stroke="#7C59FF"
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M12 23.667L23.667 12M12 12l11.667 11.667"
		/>
	</svg>
)

export default memo(CrossBurger)
