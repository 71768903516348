import React, { memo } from "react"

const Back = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
		<path
			d="M12.5 15L7.5 10L12.5 5"
			stroke="#7C59FF"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default memo(Back)
