import React, { FC } from "react"

const Telegram: FC = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd"
					d="M16 32C24.8365 32 32 24.8364 32 16C32 7.16357 24.8365 0 16 0C7.16345 0 0 7.16357 0 16C0 24.8364 7.16345 32 16 32ZM19.644 22.9468C19.385 23.0449 19.0945 23.0049 18.8691 22.8452C17.4509 21.8354 14.9505 20.002 14.9505 20.002L12.7833 22.0483C12.6328 22.1904 12.4172 22.2456 12.2156 22.1929C12.0133 22.1396 11.86 21.9858 11.7935 21.79C11.2636 20.229 10.2472 17.1592 10.2472 17.1592C10.2472 17.1592 8.07227 16.4697 6.41541 15.9741C6.17529 15.9023 6.00867 15.687 6.00031 15.4395C5.99255 15.1924 6.1452 14.9668 6.3797 14.8799C10.0743 13.5132 19.1134 10.1216 22.0485 9.03516C22.2389 8.96436 22.4524 9.00244 22.6064 9.1333C22.7604 9.26416 22.8304 9.46777 22.7884 9.66357C22.2347 12.2642 20.7591 19.5 20.1508 22.3589C20.1157 22.5229 20.0307 22.6694 19.9106 22.7812C19.8346 22.8521 19.7444 22.9087 19.644 22.9468ZM12.3374 20.6479L11.1621 16.7715L19.4879 11.4541C19.574 11.3999 19.6888 11.4185 19.7511 11.499C19.8141 11.5796 19.8036 11.6943 19.728 11.7632L12.5985 18.1934L12.3374 20.6479Z"
					fill="#8B909E" />
	</svg>
)

export default Telegram