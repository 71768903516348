import React, { memo } from "react";

const Logo = () => (
	<svg width="132" height="22" viewBox="0 0 132 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M9.71442 17.1137H0L9.00534 2.50659H18.7907L9.71442 17.1137Z" fill="#7C59FF" />
		<ellipse cx="18.8619" cy="13.2142" rx="3.89995" ry="3.89995" fill="#7C59FF" />
		<path
			d="M129.295 15.7568C128.885 16.3027 128.387 16.6917 127.8 16.9237C127.213 17.1558 126.531 17.2649 125.753 17.2513C124.757 17.224 123.924 16.9442 123.255 16.4119C122.901 16.1116 122.621 15.7568 122.416 15.3473C122.225 14.9242 122.129 14.4261 122.129 13.8528C122.143 13.266 122.273 12.7473 122.518 12.2969C122.778 11.8329 123.167 11.4507 123.685 11.1505C124.122 10.9184 124.613 10.7546 125.159 10.6591C125.719 10.5636 126.326 10.5158 126.981 10.5158H129.192C129.233 9.84703 129.076 9.34886 128.722 9.0213C128.38 8.68009 127.896 8.50266 127.268 8.48901C127.063 8.48901 126.852 8.50266 126.633 8.52996C126.415 8.55725 126.21 8.61185 126.019 8.69374C125.842 8.77563 125.685 8.89164 125.548 9.04177C125.425 9.17826 125.344 9.34886 125.303 9.55359H122.334C122.402 8.89846 122.58 8.35253 122.866 7.91578C123.167 7.46538 123.542 7.1037 123.992 6.83073C124.443 6.55776 124.941 6.36668 125.487 6.2575C126.046 6.13466 126.62 6.07324 127.207 6.07324C127.534 6.07324 127.869 6.09371 128.21 6.13466C128.551 6.16196 128.885 6.2302 129.213 6.33939C129.54 6.43493 129.848 6.57141 130.134 6.74884C130.434 6.91262 130.714 7.13099 130.974 7.40396C131.438 7.93625 131.724 8.55043 131.833 9.2465C131.956 9.92892 132.011 10.5977 131.997 11.2528V17.0875H129.356L129.295 15.7568ZM129.192 12.3174H127.084C126.879 12.3174 126.647 12.3379 126.388 12.3788C126.128 12.4198 125.903 12.4948 125.712 12.604C125.521 12.7132 125.357 12.8565 125.221 13.0339C125.098 13.2114 125.037 13.4297 125.037 13.6891C125.037 13.9074 125.077 14.0985 125.159 14.2623C125.255 14.4261 125.371 14.5626 125.507 14.6717C125.658 14.7809 125.835 14.8696 126.04 14.9379C126.244 14.9925 126.449 15.0198 126.654 15.0198C127.05 15.0334 127.418 14.9652 127.759 14.815C128.114 14.6649 128.421 14.4534 128.681 14.1804C128.844 13.962 128.967 13.7164 129.049 13.4434C129.145 13.1568 129.192 12.7814 129.192 12.3174Z"
			fill="white" />
		<path
			d="M112.871 7.5062C113.062 7.27418 113.26 7.0831 113.465 6.93297C113.669 6.76919 113.915 6.61223 114.202 6.4621C114.502 6.32561 114.802 6.23007 115.103 6.17548C115.416 6.10724 115.751 6.07312 116.106 6.07312C116.802 6.07312 117.409 6.19595 117.928 6.44162C118.446 6.67365 118.876 6.98074 119.218 7.36289C119.613 7.84059 119.893 8.40017 120.057 9.04165C120.221 9.68312 120.303 10.3928 120.303 11.1708V17.0874H117.395V11.396C117.395 11.0138 117.355 10.6522 117.273 10.3109C117.204 9.95609 117.082 9.65582 116.904 9.41015C116.727 9.15083 116.495 8.95975 116.208 8.83692C115.935 8.71408 115.594 8.65267 115.184 8.65267C114.775 8.66631 114.406 8.76185 114.079 8.93928C113.765 9.10306 113.519 9.32144 113.342 9.59441C113.178 9.86737 113.055 10.1745 112.973 10.5157C112.905 10.8432 112.871 11.1844 112.871 11.5393V17.0874H109.984V2.59277H112.871V7.5062Z"
			fill="white" />
		<path
			d="M99.8358 7.75195C100.259 7.23331 100.784 6.83068 101.412 6.54407C102.054 6.2438 102.695 6.08685 103.337 6.0732C104.101 6.05955 104.811 6.19603 105.466 6.48265C106.121 6.75562 106.667 7.21966 107.104 7.87479C107.458 8.35248 107.718 8.91207 107.882 9.55354C108.045 10.195 108.127 10.8706 108.127 11.5803C108.127 12.4675 108.018 13.2727 107.8 13.9961C107.595 14.7058 107.24 15.32 106.735 15.8386C106.312 16.3027 105.793 16.6575 105.179 16.9032C104.565 17.1352 103.951 17.2512 103.337 17.2512C103.036 17.2512 102.736 17.2308 102.436 17.1898C102.136 17.1625 101.835 17.1011 101.535 17.0056C101.235 16.91 100.935 16.7735 100.634 16.5961C100.348 16.4187 100.075 16.1798 99.8153 15.8796V21.5914H96.9287V6.2984H99.672L99.8358 7.75195ZM102.538 8.65275C102.115 8.65275 101.733 8.73464 101.392 8.89842C101.064 9.04855 100.784 9.2601 100.552 9.53307C100.32 9.80604 100.136 10.1199 99.9996 10.4748C99.8768 10.8297 99.8153 11.2118 99.8153 11.6213C99.8017 12.5357 100.041 13.2727 100.532 13.8323C101.037 14.3783 101.719 14.6649 102.579 14.6922C103.043 14.6922 103.439 14.6171 103.767 14.467C104.094 14.3168 104.36 14.1053 104.565 13.8323C104.783 13.5593 104.947 13.2386 105.056 12.8701C105.179 12.5016 105.241 12.099 105.241 11.6622C105.241 11.2391 105.179 10.8433 105.056 10.4748C104.947 10.1063 104.777 9.78556 104.545 9.5126C104.326 9.23963 104.053 9.02808 103.726 8.87794C103.398 8.72781 103.002 8.65275 102.538 8.65275Z"
			fill="white" />
		<path d="M94.6366 17.0874H91.75V2.59277H94.6366V17.0874Z" fill="white" />
		<path
			d="M80.3886 14.3236L79.3445 17.0874H76.1099L81.7194 2.59277H85.0769L90.6045 17.0874H87.3493L86.3462 14.3236H80.3886ZM81.2485 11.7031H85.4658L83.3776 5.72508L81.2485 11.7031Z"
			fill="white" />
		<path d="M74.8385 10.905H68.103V8.48926H74.8385V10.905Z" fill="white" />
		<path
			d="M56.1661 9.7993C56.1661 10.4135 56.2412 11.0072 56.3913 11.5804C56.5551 12.1537 56.794 12.6586 57.1079 13.0954C57.4354 13.5321 57.8449 13.887 58.3362 14.16C58.8412 14.4193 59.4418 14.549 60.1378 14.549C61.0113 14.549 61.7279 14.3579 62.2875 13.9757C62.8607 13.5799 63.2155 13.034 63.352 12.3379H66.2182C66.15 13.1432 65.9384 13.8529 65.5835 14.4671C65.2287 15.0676 64.7715 15.5726 64.2119 15.982C63.6523 16.3915 63.0176 16.6986 62.3079 16.9033C61.5982 17.108 60.8612 17.2104 60.0969 17.2104C58.9641 17.2104 57.9609 17.0261 57.0874 16.6576C56.2276 16.2891 55.4974 15.7773 54.8968 15.1222C54.31 14.4671 53.8664 13.6891 53.5661 12.7883C53.2659 11.8875 53.1157 10.898 53.1157 9.81978C53.1157 8.76885 53.2659 7.79299 53.5661 6.89219C53.88 5.97775 54.3373 5.19297 54.9378 4.53784C55.5383 3.86907 56.2685 3.35043 57.1284 2.98192C58.0019 2.59977 59.005 2.40869 60.1378 2.40869C60.9294 2.40869 61.6733 2.50423 62.3693 2.69531C63.0654 2.88639 63.6864 3.17983 64.2323 3.57563C64.7783 3.97143 65.2219 4.48325 65.5631 5.11108C65.9179 5.72525 66.1363 6.46227 66.2182 7.32212H63.352C63.2019 6.62605 62.847 6.07329 62.2875 5.66384C61.7279 5.25438 61.0113 5.04966 60.1378 5.04966C59.4418 5.04966 58.8412 5.17932 58.3362 5.43864C57.8449 5.68431 57.4354 6.02552 57.1079 6.46227C56.794 6.89902 56.5551 7.41083 56.3913 7.99771C56.2412 8.57095 56.1661 9.17148 56.1661 9.7993Z"
			fill="white" />
		<path d="M40.2056 5.23374V2.59277H52.2639V5.23374H47.719V17.0874H44.7505V5.23374H40.2056Z" fill="white" />
		<path
			d="M27.6426 17.0874V2.59277H34.071C34.7397 2.59277 35.3539 2.66102 35.9135 2.7975C36.4867 2.93398 36.9849 3.17965 37.408 3.53451C37.7492 3.80748 38.0358 4.16916 38.2679 4.61956C38.5135 5.05631 38.6364 5.5886 38.6364 6.21643C38.65 6.8852 38.5272 7.4789 38.2679 7.99754C38.0222 8.51618 37.5991 8.95293 36.9986 9.30779C37.4217 9.43063 37.7833 9.61488 38.0836 9.86055C38.3839 10.1062 38.6227 10.3928 38.8002 10.7204C38.9912 11.048 39.1277 11.4028 39.2096 11.785C39.2915 12.1671 39.3256 12.5629 39.312 12.9724C39.2847 13.6275 39.1072 14.2349 38.7797 14.7944C38.4658 15.3404 38.0495 15.784 37.5308 16.1252C36.9167 16.5483 36.2411 16.8144 35.5041 16.9236C34.767 17.0328 34.0369 17.0874 33.3135 17.0874H27.6426ZM33.2316 8.46841C33.5319 8.46841 33.8321 8.45476 34.1324 8.42747C34.4327 8.38652 34.7125 8.29098 34.9718 8.14085C35.1901 8.00437 35.3744 7.82011 35.5245 7.58809C35.6747 7.35607 35.7566 7.0831 35.7702 6.76919C35.7839 6.44162 35.7293 6.17548 35.6064 5.97075C35.4836 5.76603 35.3335 5.60907 35.156 5.49988C34.9377 5.3634 34.6852 5.28833 34.3985 5.27469C34.1119 5.24739 33.8185 5.23374 33.5182 5.23374H30.6111V8.46841H33.2316ZM30.6111 14.4669H33.3135C33.7093 14.4669 34.1256 14.4464 34.5623 14.4055C35.0127 14.3509 35.3949 14.2076 35.7088 13.9755C35.8999 13.8254 36.0568 13.6412 36.1797 13.4228C36.3025 13.1908 36.3639 12.9041 36.3639 12.5629C36.3639 12.249 36.2957 11.9829 36.1592 11.7645C36.0227 11.5461 35.8521 11.3823 35.6474 11.2732C35.4153 11.123 35.1492 11.0343 34.8489 11.007C34.5623 10.9797 34.2621 10.9661 33.9481 10.9661L30.6111 10.9865V14.4669Z"
			fill="white" />
	</svg>

);

export default memo(Logo);
