import X from "assets/icons/social/X";
import Telegram from "assets/icons/social/Telegram";

interface ISocialNetwork {
	link: string;
	icon: React.FC;
	label: string;
}

export const socialNetworks = (locale = "en"): ISocialNetwork[] => [
	{
		link: "https://x.com/btcalpha",
		label: "X",
		icon: X
	},
	{
		link: "https://t.me/btcalpha",
		label: "Telegram",
		icon: Telegram
	},
];
