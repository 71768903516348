import React from "react"
import {
	applySnapshot,
	cast,
	flow,
	getParent,
	getSnapshot,
	Instance,
	types as t,
} from "mobx-state-tree"
import * as yup from "yup"

import formMessages from "messages/form"
import RegisterService from "services/RegisterService"
import {
	IRegisterBody,
	IRegisterJuridicalInfoBody,
	IRegisterPersonalInfoBody,
	IRegisterSendPinCodeBody,
} from "types/register"
import { errorsFromSchema, validateSchema } from "utils/yup"
import { MessageFormatter } from "types/general"
import errorHandler from "utils/errorHandler"
import { PASSWORD_MAX_SYMBOLS } from "constants/common"
import { IRootStore } from "../Root"

const RegisterErrors = t.model({
	email: t.optional(t.string, ""),
	password: t.optional(t.string, ""),
	invite: t.optional(t.string, ""),
	isConditionsAccepted: t.optional(t.string, ""),
	personalFirstName: t.optional(t.string, ""),
	personalLastName: t.optional(t.string, ""),
	countryCode: t.optional(t.string, ""),
	pinCode: t.optional(t.boolean, false),
	companyName: t.optional(t.string, ""),
	companyID: t.optional(t.string, ""),
	beneficeFirstName: t.optional(t.string, ""),
	beneficeLastName: t.optional(t.string, ""),
	beneficePosition: t.optional(t.string, ""),
})

interface IRegisterErrors extends Instance<typeof RegisterErrors> {}

export const Register = t
	.model({
		// email
		email: t.optional(t.string, ""),

		//password and validation for view
		password: t.optional(t.string, ""),
		isPassErrorLength: t.optional(t.boolean, true),
		isPassNumber: t.optional(t.boolean, true),
		isPassSpecialChar: t.optional(t.boolean, true),
		isPassLowercase: t.optional(t.boolean, true),
		isPassUppercase: t.optional(t.boolean, true),

		// referral code
		inviteCode: t.optional(t.string, ""),

		// conditions checkbox
		isConditionsAccepted: t.optional(t.boolean, false),

		// pincode
		pinCode: t.optional(t.string, ""),
		pinCodeTimeout: t.optional(t.string, ""),
		pinCodeGeneratedAt: t.optional(t.string, ""),
		pinCodeTriesLeft: t.optional(t.number, 0),

		// personal account data
		personalFirstName: t.optional(t.string, ""),
		personalLastName: t.optional(t.string, ""),

		// juridical account data
		companyName: t.optional(t.string, ""),
		companyID: t.optional(t.string, ""),
		beneficeFirstName: t.optional(t.string, ""),
		beneficeLastName: t.optional(t.string, ""),
		beneficePosition: t.optional(t.string, ""),

		// country code
		countryCode: t.optional(t.string, ""),

		// state for loading button
		isLoading: t.optional(t.boolean, false),

		// personal or juridical account, if true it's personal
		isPersonal: t.optional(t.boolean, true),

		// registration step, if '' -> 1st step
		step: t.optional(t.string, ""),
		slug: t.optional(t.string, ""),

		// form errors
		errors: t.optional(RegisterErrors, {}),

		cloudflareToken: t.optional(t.string, ""),
		isErrorCloudflareToken: t.optional(t.boolean, false),
	})
	.actions(self => {
		const initialState = getSnapshot(self)
		return {
			resetState() {
				applySnapshot(self, initialState)
			},
			resetRegisterState() {
				self.email = ""
				self.password = ""
				self.isPassErrorLength = true
				self.isPassSpecialChar = true
				self.isPassLowercase = true
				self.isPassUppercase = true
				self.isPassNumber = true
				self.inviteCode = ""
				self.isConditionsAccepted = false
				self.isLoading = false
				self.isPersonal = true
			},
			resetRegisterPinCodeState() {
				self.pinCode = ""
				self.pinCodeTimeout = ""
				self.pinCodeGeneratedAt = ""
				self.pinCodeTriesLeft = 0
				self.errors.pinCode = false
			},
			resetRegisterPersonalState() {
				self.personalFirstName = ""
				self.personalLastName = ""
				self.countryCode = ""
				self.errors.countryCode = ""
			},
			resetRegisterJuridicalState() {
				self.companyName = ""
				self.companyID = ""
				self.beneficeFirstName = ""
				self.beneficeLastName = ""
				self.beneficePosition = ""
				self.countryCode = ""
				self.errors.countryCode = ""
			},
		}
	})
	.actions(self => ({
		setEmail(email: string) {
			self.email = email
			self.errors.email = ""
		},
		setCloudflareToken(token: string) {
			self.cloudflareToken = token
			self.isErrorCloudflareToken = false
		},
		setIsErrorCloudflareToken(v: boolean) {
			self.isErrorCloudflareToken = v
		},
		setPassword(nextPassword: string) {
			self.password = nextPassword
			self.errors.password = ""

			// Password validation
			self.isPassErrorLength = nextPassword.length < 8
			self.isPassNumber = !/\d/.test(nextPassword)
			// self.isPassSpecialChar = !/[!@#$%^&*(),.?":{}|<>]/.test(nextPassword)
			self.isPassLowercase = !/[a-z]/.test(nextPassword)
			self.isPassUppercase = !/[A-Z]/.test(nextPassword)
		},
		setPersonal() {
			self.isPersonal = true
		},
		setJuridical() {
			self.isPersonal = false
		},
		setInviteCode(inviteCode: string) {
			self.inviteCode = inviteCode
		},
		setIsConditionAccepted(e: React.ChangeEvent<HTMLInputElement>) {
			const { checked } = e.target

			self.isConditionsAccepted = checked
			self.errors.isConditionsAccepted = ""
		},
		setErrors(nextErrors: IRegisterErrors) {
			self.errors = cast(nextErrors)
		},
		setIsLoading(isLoading: boolean) {
			self.isLoading = isLoading
		},
		setPersonalFirstName(firstName: string) {
			self.personalFirstName = firstName

			self.errors.personalFirstName = ""
		},
		setPersonalLastName(lastName: string) {
			self.personalLastName = lastName

			self.errors.personalLastName = ""
		},
		setCountryCode(country: string) {
			self.countryCode = country

			self.errors.countryCode = ""
		},
		setCompanyName(companyName: string) {
			self.companyName = companyName

			self.errors.companyName = ""
		},
		setCompanyID(companyID: string) {
			self.companyID = companyID

			self.errors.companyID = ""
		},
		setBeneficeFirstName(beneficeFirstName: string) {
			self.beneficeFirstName = beneficeFirstName

			self.errors.beneficeFirstName = ""
		},
		setBeneficeLastName(beneficeLastName: string) {
			self.beneficeLastName = beneficeLastName

			self.errors.beneficeLastName = ""
		},
		setBeneficePosition(beneficePosition: string) {
			self.beneficePosition = beneficePosition

			self.errors.beneficePosition = ""
		},
		setPinCode(pinCode: string) {
			self.pinCode = pinCode

			self.errors.pinCode = false
		},
		backToFirstStep() {
			self.step = ""
		},
	}))
	.actions(self => ({
		async validateFirstStepRegister(formatMessage: MessageFormatter): Promise<boolean> {
			try {
				// Validating for first step
				await validateSchema({
					email: [self.email, yup.string().email().required(formatMessage(formMessages.required))],
					password: [
						self.password,
						yup
							.string()
							.required(formatMessage(formMessages.required))
							.min(8, "Please make sure your password meets all the requirements to continue.")
							// .matches(
							// 	/[!@#$%^&*(),.?":{}|<>]/,
							// 	'Password must contain at least one special character (!@#$%^&*(),.?":{}|<>)',
							// )
							.matches(
								/\d/,
								"Please make sure your password meets all the requirements to continue.",
							)
							.matches(
								/[a-z]/,
								"Please make sure your password meets all the requirements to continue.",
							)
							.matches(
								/[A-Z]/,
								"Please make sure your password meets all the requirements to continue.",
							)
							.max(PASSWORD_MAX_SYMBOLS),
					],
					inviteCode: [self.inviteCode, yup.string()],
					isConditionsAccepted: [
						self.isConditionsAccepted,
						yup.boolean().oneOf([true], "Required"),
					],
				})

				return true
			} catch (err) {
				self.setErrors(errorsFromSchema<IRegisterErrors>(err as any) as IRegisterErrors)

				return false
			}
		},
		async validateJuridicalDataRegister(formatMessage: MessageFormatter): Promise<boolean> {
			try {
				// Validating for first step
				await validateSchema({
					companyName: [
						self.companyName,
						yup.string().required(formatMessage(formMessages.required)),
					],
					companyID: [self.companyID, yup.string().required(formatMessage(formMessages.required))],
					beneficeFirstName: [
						self.beneficeFirstName,
						yup.string().required(formatMessage(formMessages.required)),
					],
					beneficeLastName: [
						self.beneficeLastName,
						yup.string().required(formatMessage(formMessages.required)),
					],
					beneficePosition: [
						self.beneficePosition,
						yup.string().required(formatMessage(formMessages.required)),
					],
					countryCode: [
						self.countryCode,
						yup.string().required(formatMessage(formMessages.required)),
					],
				})

				return true
			} catch (err) {
				self.setErrors(errorsFromSchema<IRegisterErrors>(err as any) as IRegisterErrors)

				return false
			}
		},
		async validatePersonalDataRegister(formatMessage: MessageFormatter): Promise<boolean> {
			try {
				// Validating for first step
				await validateSchema({
					personalFirstName: [
						self.personalFirstName,
						yup.string().required(formatMessage(formMessages.required)),
					],
					personalLastName: [
						self.personalLastName,
						yup.string().required(formatMessage(formMessages.required)),
					],
					countryCode: [
						self.countryCode,
						yup.string().required(formatMessage(formMessages.required)),
					],
				})

				return true
			} catch (err) {
				self.setErrors(errorsFromSchema<IRegisterErrors>(err as any) as IRegisterErrors)

				return false
			}
		},
	}))
	.actions(self => ({
		onSubmitFirstStep: flow(function* (
			formatMessage: MessageFormatter,
			resetTokenAction: () => void,
		) {
			// Validate register form
			const isValid = yield self.validateFirstStepRegister(formatMessage)
			if (!isValid) {
				return
			}

			// set loading state
			self.isLoading = true

			// Register user body
			const body: IRegisterBody = {
				email: self.email,
				password: self.password,
				type: self.isPersonal ? 1 : 2,
				invite: self.inviteCode || undefined,
				ecaptcha: self.cloudflareToken || undefined,
			}

			try {
				const res = yield RegisterService.register(body)

				self.step = res.step
				self.slug = res.slug ? res.slug : ""
				self.pinCodeTimeout = res.pincode_timeout ? res.pincode_timeout : ""
				self.pinCodeGeneratedAt = res.pincode_generated_at ? res.pincode_generated_at : ""
				self.pinCodeTriesLeft = res.pincode_tries_left ? res.pincode_tries_left : 0
			} catch (err) {
				resetTokenAction()
				errorHandler(err)
			} finally {
				self.isLoading = false
			}
		}),
		onSubmitJuridicalStep: flow(function* (formatMessage: MessageFormatter) {
			// Validate juridical info register form
			const isValid = yield self.validateJuridicalDataRegister(formatMessage)
			if (!isValid) {
				return
			}

			// set loading state
			self.isLoading = true

			// Register juridical account body
			const body: IRegisterJuridicalInfoBody = {
				company_id: self.companyID,
				company_name: self.companyName,
				company_jurisdiction: self.countryCode,
				benefice_first_name: self.beneficeFirstName,
				benefice_last_name: self.beneficeLastName,
				benefice_position: self.beneficePosition,
			}

			try {
				yield RegisterService.registerJuridicalInfo(body, self.slug)

				self.step = "finish"
			} catch (err) {
				errorHandler(err)
			} finally {
				self.isLoading = false
			}
		}),
		onSubmitFinishStep: flow(function* () {
			// set loading state
			self.isLoading = true

			try {
				yield RegisterService.registerComplete(self.slug)
				// cookies.set(config.sessionCookieName, res.token)

				const global = getParent<IRootStore>(self).global
				global.setIsAuthenticated(true)
			} catch (err) {
				errorHandler(err)
			} finally {
				self.isLoading = false
			}
		}),
		onSubmitPersonalStep: flow(function* (formatMessage: MessageFormatter) {
			// Validate personal info register form
			const isValid = yield self.validatePersonalDataRegister(formatMessage)
			if (!isValid) {
				return
			}

			// set loading state
			self.isLoading = true

			// Register personal account body
			const body: IRegisterPersonalInfoBody = {
				first_name: self.personalFirstName,
				last_name: self.personalLastName,
				citizenship: self.countryCode,
			}

			try {
				yield RegisterService.registerPersonalInfo(body, self.slug)

				self.step = "finish"
			} catch (err) {
				errorHandler(err)
			} finally {
				self.isLoading = false
			}
		}),
		sendPinCodeToEmail: flow(function* () {
			// set loading state
			self.isLoading = true

			const body: IRegisterSendPinCodeBody = {
				pincode: self.pinCode,
			}

			try {
				const res = yield RegisterService.registerPinCode(body, self.slug)

				self.step = res.step
				self.pinCodeTimeout = res.pincode_timeout ? res.pincode_timeout : ""
				self.pinCodeGeneratedAt = res.pincode_generated_at ? res.pincode_generated_at : ""
				self.pinCodeTriesLeft = res.pincode_tries_left ? res.pincode_tries_left : 0
			} catch (err) {
				self.errors.pinCode = true

				errorHandler(err)
			} finally {
				self.isLoading = false
			}
		}),
		resendPinCodeToEmail: flow(function* () {
			// set loading state
			self.isLoading = true

			try {
				const res = yield RegisterService.registerResendPinCode(self.slug)

				self.pinCodeTimeout = res.pincode_timeout ? res.pincode_timeout : ""
				self.pinCodeGeneratedAt = res.pincode_generated_at ? res.pincode_generated_at : ""
				self.pinCodeTriesLeft = res.pincode_tries_left ? res.pincode_tries_left : 0
			} catch (err) {
				errorHandler(err)
			} finally {
				self.isLoading = false
			}
		}),
	}))

export type IRegister = Instance<typeof Register>
