import { LanguageCode, ChartingLibraryFeatureset } from "charting_library/charting_library"
import colors from "./colors"

export const getOverrides = (theme: string) => ({
	priceScaleSelectionStrategyName: "right",
	"paneProperties.background": colors[theme].card,
	"paneProperties.backgroundType": "solid",
	"paneProperties.legendProperties.showVolume": true,
	"paneProperties.backgroundGradientStartColor": colors[theme].card,
	"paneProperties.backgroundGradientEndColor": colors[theme].card,
	"paneProperties.vertGridProperties.color": colors[theme].grid,
	"paneProperties.horzGridProperties.color": colors[theme].grid,
	"mainSeriesProperties.lineStyle.color": colors[theme].blue,
	"mainSeriesProperties.candleStyle.upColor": colors[theme].green,
	"mainSeriesProperties.candleStyle.downColor": colors[theme].red,
	"mainSeriesProperties.candleStyle.borderUpColor": colors[theme].green,
	"mainSeriesProperties.candleStyle.borderDownColor": colors[theme].red,
	"mainSeriesProperties.candleStyle.wickUpColor": colors[theme].green,
	"mainSeriesProperties.candleStyle.wickDownColor": colors[theme].red,
	"mainSeriesProperties.hollowCandleStyle.upColor": colors[theme].green,
	"mainSeriesProperties.hollowCandleStyle.downColor": colors[theme].red,
	"mainSeriesProperties.hollowCandleStyle.borderUpColor": colors[theme].green,
	"mainSeriesProperties.hollowCandleStyle.borderDownColor": colors[theme].red,
	"mainSeriesProperties.haStyle.upColor": colors[theme].green,
	"mainSeriesProperties.haStyle.downColor": colors[theme].red,
	"mainSeriesProperties.haStyle.borderUpColor": colors[theme].green,
	"mainSeriesProperties.haStyle.borderDownColor": colors[theme].red,
})

export const getStudiesOverrides = (theme: string) => ({
	"volume.volume.color.0": colors[theme].volumeRed,
	"volume.volume.color.1": colors[theme].volumeGreen,
})

export const enabledFeatures: ChartingLibraryFeatureset[] = [
	"hide_last_na_study_output",
	"chart_template_storage",
	"dont_show_boolean_study_arguments",
	"hide_left_toolbar_by_default",
	"create_volume_indicator_by_default",
]

export const disabledFeatures: ChartingLibraryFeatureset[] = [
	"header_symbol_search",
	"symbol_info",
	"edit_buttons_in_legend",
	"header_compare",
	"header_undo_redo",
	"header_fullscreen_button",
	"header_quick_search",
]

export const getLoadingScreen = (theme: string) => ({
	backgroundColor: colors[theme].card,
	foregroundColor: colors[theme].card,
})

export const availableLocales: LanguageCode[] = ["en", "zh", "ru", "fr", "de", "pt", "es", "tr"]
