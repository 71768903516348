import React, { FC, useRef, useImperativeHandle, forwardRef } from "react"
import { Turnstile, TurnstileTheme } from "@marsidev/react-turnstile"

import config from "helpers/config"
import styles from "./index.module.scss"

const CloudflareCaptcha: FC<{
	setToken: (token: string) => void
	size?: "auto" | "normal" | "compact" | "invisible"
	theme: TurnstileTheme
	isError?: boolean
	ref?: any
}> = forwardRef(({ size = "normal", setToken, theme = "dark", isError }, ref) => {
	const internalRef = useRef()
	const onExpire = () => setToken("")

	useImperativeHandle(ref, () => ({
		resetAction: () => {
			// @ts-ignore
			internalRef.current?.reset()
			setToken("")
		},
	}))

	return (
		<div className={isError ? styles.wobble : undefined}>
			<Turnstile
				ref={internalRef}
				options={{ theme, size, language: "auto" }}
				siteKey={config.cloudflareSiteKey}
				onSuccess={setToken}
				onExpire={onExpire}
			/>
		</div>
	)
})

export default CloudflareCaptcha
