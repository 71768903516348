import React, { FC } from "react"

const X: FC = () => (
	<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_97_3462)">
			<path fillRule="evenodd" clipRule="evenodd"
						d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32ZM13.9192 16.8646L8.03707 9H12.5706L16.4268 14.1559L21.1994 9H22.5317L17.022 14.952L23.2353 23.2589H18.7018L14.5147 17.6605L9.33229 23.2589H8L13.9192 16.8646ZM12.0789 9.98122H9.99626L19.1931 22.2777H21.2758L12.0789 9.98122Z"
						fill="#8B909E" />
		</g>
		<defs>
			<clipPath id="clip0_97_3462">
				<rect width="32" height="32" rx="16" fill="white" />
			</clipPath>
		</defs>
	</svg>
)

export default X