import React from "react"
import * as Sentry from "@sentry/react"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import ReactGA from "react-ga4"
import TagManager from "react-gtm-module"
import config from "helpers/config"
import LanguageProvider from "providers/LanguageProvider"
import { Provider, rootStore } from "models/Root"
import App from "./App"
import "./index.scss"

const tagManagerArgs = {
	gtmId: config.gtmID,
}

if (config.gtmID) {
	TagManager.initialize(tagManagerArgs)
}

if (config.gaID) {
	ReactGA.initialize(config.gaID)
}

if (config.isDebug) {
	Sentry.init({
		dsn: "https://9de132a839a2f66adc5227a30fe5dfb2@o4506269194780672.ingest.sentry.io/4506269196812288",
		integrations: [
			new Sentry.BrowserTracing({
				// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
				tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
			}),
			new Sentry.Replay(),
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, // Capture 100% of the transactions
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	})
}

const root = createRoot(document.getElementById("root") ?? document.createElement("div"))

root.render(
	<Provider value={rootStore}>
		<BrowserRouter>
			<LanguageProvider>
				<App />
			</LanguageProvider>
		</BrowserRouter>
	</Provider>,
)
