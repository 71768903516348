import React, { memo } from "react"

const LowFees = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="35" height="30" fill="none" viewBox="0 0 35 30">
		<path
			fill="#B2B7C6"
			d="M20.682 16.875c1.326 0 2.453-.547 3.38-1.64.928-1.094 1.392-2.422 1.392-3.985 0-1.563-.463-2.89-1.392-3.984-.928-1.094-2.054-1.641-3.38-1.641s-2.453.547-3.38 1.64c-.929 1.094-1.393 2.423-1.393 3.985 0 1.563.464 2.89 1.392 3.984.928 1.094 2.055 1.641 3.38 1.641zM9.545 22.5c-.875 0-1.624-.367-2.247-1.102-.624-.735-.935-1.618-.934-2.648v-15c0-1.031.311-1.914.935-2.65C7.923.367 8.672 0 9.545 0h22.273c.875 0 1.625.368 2.248 1.103.624.734.935 1.617.934 2.647v15c0 1.031-.312 1.914-.935 2.65-.624.734-1.373 1.101-2.247 1.1H9.545zM3.182 30c-.875 0-1.624-.367-2.248-1.102C.31 28.163-.001 27.28 0 26.25V7.5c0-.531.153-.977.458-1.337.306-.36.683-.54 1.133-.538.45 0 .829.18 1.134.54.306.36.458.805.457 1.335v18.75h25.454c.451 0 .83.18 1.135.54.305.36.457.805.456 1.335 0 .531-.152.977-.458 1.337-.305.36-.683.54-1.133.538H3.182zM9.545 7.5c.876 0 1.625-.367 2.248-1.103.624-.735.935-1.617.934-2.647H9.545V7.5zm22.273 0V3.75h-3.182c0 1.031.312 1.914.936 2.65.623.734 1.372 1.101 2.246 1.1zM9.545 18.75h3.182c0-1.031-.312-1.914-.935-2.65-.624-.734-1.373-1.101-2.247-1.1v3.75zm19.091 0h3.182V15c-.875 0-1.624.367-2.248 1.102-.623.735-.935 1.618-.934 2.648z"
		/>
	</svg>
)

export default memo(LowFees)
