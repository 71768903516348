import React, { memo } from "react"

const CheckMark = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
		<rect width="24" height="24" fill="#7C59FF" rx="12" />
		<path
			fill="#fff"
			fillRule="evenodd"
			d="M17.805 7.529c.26.26.26.682 0 .942l-7.334 7.334a.667.667 0 01-.942 0L6.195 12.47a.667.667 0 11.943-.942L10 14.39l6.862-6.862c.26-.26.682-.26.943 0z"
			clipRule="evenodd"
		/>
	</svg>
)

export default memo(CheckMark)
