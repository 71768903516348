import React, { memo } from "react"

const TriangleRedIcon = () => (
	<svg
		width="183"
		height="201"
		viewBox="0 0 183 201"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M156.728 1.92682C161.16 -1.13878 167.248 1.74259 167.687 7.11327L182.845 192.525C183.284 197.896 177.745 201.728 172.874 199.423L4.7235 119.844C-0.147185 117.539 -0.696032 110.826 3.73558 107.76L156.728 1.92682Z"
			fill="#FF67F4"
			fillOpacity="0.2"
		/>
		<path
			d="M157.012 2.33802C161.127 -0.508603 166.781 2.16695 167.189 7.15401L182.347 192.566C182.755 197.553 177.611 201.111 173.088 198.971L4.93739 119.392C0.414606 117.252 -0.0950387 111.018 4.02003 108.171L157.012 2.33802Z"
			stroke="#FF67F4"
			strokeOpacity="0.15"
		/>
	</svg>
)

export default memo(TriangleRedIcon)
