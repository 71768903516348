import React, { memo } from "react"

const ArrowRight = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="28" height="14" viewBox="0 0 28 14" fill="none">
		<path
			d="M1 7H27"
			stroke="#7C59FF"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M21 1L27 7L21 13"
			stroke="#7C59FF"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default memo(ArrowRight)
