import React, { FC, useMemo, useRef, useState } from "react"
import { observer } from "mobx-react-lite"

import ArrowPrecision from "assets/icons/terminal/ArrowPrecision"
import { useOnClickOutside } from "hooks/useOnClickOutside"
import { toPrecisionSymbol } from "helpers/exchange"
import { useMst } from "models/Root"
import styles from "./index.module.scss"

const Precision: FC = () => {
	const { precisionArray, setOrderBookPrecision, orderBookPrecision } = useMst().terminal
	const [isOpen, toggleOpen] = useState(false)
	const dropdownRef = useRef<HTMLDivElement | null>(null)
	const currentPrecision = useMemo(
		() => toPrecisionSymbol(orderBookPrecision),
		[orderBookPrecision],
	)

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		toggleOpen(event.target.checked)
	}

	const handleCheckboxClose = () => toggleOpen(false)

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const seter: any = (precision: number) => {
		setOrderBookPrecision(+precision)
		handleCheckboxClose()
	}

	useOnClickOutside(dropdownRef, handleCheckboxClose)

	return (
		<div ref={dropdownRef} className={styles.precision}>
			<input
				type="checkbox"
				id="PrecisionCoin"
				checked={isOpen}
				onChange={handleCheckboxChange}
				className={`visually-hidden ${styles.precision__checkbox}`}
			/>

			<label className={styles.precision__toggleButton} htmlFor="PrecisionCoin">
				<span className={styles.precision__toggleButtonText}>{currentPrecision}</span>

				<ArrowPrecision />
			</label>

			<ul className={styles.precision__list}>
				{precisionArray.map(item => {
					const action = () => seter(item[0])

					return (
						<li key={item[1]} className={styles.precision__item}>
							<button onClick={action} className={styles.precision__itemButton} type="button">
								{item[1]}
							</button>
						</li>
					)
				})}
			</ul>
		</div>
	)
}

export default observer(Precision)
