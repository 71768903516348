export const colors: Record<string, Record<string, string>> = {
	light: {
		primary: "#030303",
		background: "#fafafa",
		card: "#ffffff",
		blue: "#007aff",
		green: "#00b172",
		red: "#ff5f66",
		grid: "#e5e5e5",
		volumeRed: "rgba(255, 95, 102, 0.2)",
		volumeGreen: "rgba(0, 177, 114, 0.2)",
		orderbookRedPrimary: "#ff5f66",
		orderbookGreenPrimary: "#00b761",
	},
	dark: {
		primary: "#ffffff",
		background: "#000000",
		card: "#000000",
		blue: "#1485ff",
		green: "#00b172",
		red: "#ff5f66",
		grid: "#111",
		volumeRed: "rgba(255, 95, 102, 0.2)",
		volumeGreen: "rgba(0, 177, 114, 0.2)",
		orderbookRedPrimary: "#ff5f66",
		orderbookGreenPrimary: "#21c177",
	},
}

export const colorsTerminal: Record<string, Record<string, string>> = {
	light: {
		primary: "#030303",
		background: "#fafafa",
		card: "#ffffff",
		blue: "#007aff",
		green: "#00b172",
		red: "#ff5f66",
		grid: "#e5e5e5",
		volumeRed: "rgba(255, 95, 102, 0.2)",
		volumeGreen: "rgba(0, 177, 114, 0.2)",
		orderbookRedPrimary: "#ff5f66",
		orderbookGreenPrimary: "#00b761",
	},
	dark: {
		primary: "#ffffff",
		background: "#000",
		card: "#101014",
		blue: "#1485ff",
		green: "#00b172",
		red: "#ff5f66",
		grid: "#111",
		volumeRed: "rgba(255, 95, 102, 0.2)",
		volumeGreen: "rgba(0, 177, 114, 0.2)",
		orderbookRedPrimary: "#ff5f66",
		orderbookGreenPrimary: "#21c177",
	},
}
