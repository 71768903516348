import React, { useEffect, useState } from "react"
import { IntlProvider, MessageFormatElement } from "react-intl"
import { observer } from "mobx-react-lite"
import cookie from "js-cookie"
import dayjs from "utils/dayjs"

import { useMst } from "models/Root"
import { CONTENT_LOCALE_CACHE_KEY } from "utils/cacheKeys"
import { translationMessages } from "./i18n"

export function changeContentLocale(locales: string[]) {
	cookie.set(CONTENT_LOCALE_CACHE_KEY, locales.join("+"), { expires: 365 })
}

const LanguageProvider: React.FC<{
	children?: React.ReactNode
}> = ({ children }) => {
	const [messages, setMessages] = useState<Record<string, Record<string, MessageFormatElement[]>>>(
		{},
	)

	const { global } = useMst()

	useEffect(() => {
		window.onpopstate = () => {
			const { pathname } = window.location
			const splittedPath = pathname.split("/")
			const nextLocale = splittedPath[1]

			if (nextLocale !== global.locale) {
				// TODO: UPDATE PROFILE LANGUAGE
			}
		}
	}, [])

	useEffect(() => {
		translationMessages(global.locale).then(
			(nextMessages: Record<string, MessageFormatElement[]> | any) => {
				setMessages(nextMessages)
				dayjs.locale(global.locale)
			},
		)
	}, [global.locale])

	return (
		<IntlProvider locale={global.locale} key={global.locale} messages={messages[global.locale]}>
			{React.Children.only(children)}
		</IntlProvider>
	)
}

export default observer(LanguageProvider)
