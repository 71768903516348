import React, { memo } from "react"

const CheckQ = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
		<circle cx="14" cy="14" r="14" fill="#7C59FF" />
		<path
			d="M9 14.9375L12.0625 18L19.0625 11"
			stroke="white"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
)

export default memo(CheckQ)
