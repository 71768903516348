import { useCallback, useEffect, useMemo, useState } from "react"
import Decimal from "decimal.js"
import { useIntl } from "react-intl"
import { toast } from "react-toastify"

import { useMst } from "models/Root"
import { formatNumberNoRounding } from "utils/format"
import { ICreateOrderBody, TradeActionsEnum, TradeTypeEnum } from "types/exchange"
import { orderBook } from "models/Terminal/orderBook"
import useAccountType from "hooks/useAccountType"
import { ORDER_SIDE, ORDER_TYPE } from "constants/orders"
import { OrderTypeEnum } from "types/orders"
import { ACCOUNT_TYPE } from "constants/exchange"
import { formatErrorFromServer, onSubmitValidate } from "helpers/exchange"
import ExchangeService from "services/ExchangeService"
import { queryVars } from "../../../constants/query"
import { AccountTypeEnum } from "../../../types/account"

const formatStringToNumber = (str: string): number => {
	if (str === "") {
		return 0
	}

	return new Decimal(
		str
			.replace(/ /g, "")
			.replace(/[^0-9.,]/g, "")
			.replace(/,/g, "."),
	).toNumber()
}

type ErrorsState =
	| {
			[key: string]: string
	  }
	| object

const useTradingForm = () => {
	const { formatNumber } = useIntl()
	const accountType = useAccountType()
	const {
		account: {
			balancesCrossBorrowsTerminal,
			currentBalance,
			loadBalances,
			maintenanceMargin,
			initialMargin,
			loadMarginStatus,
		},
		terminal: {
			pair,
			recentTrade,
			isLoaded,
			tradeForm,
			baseCurrencyCode,
			quoteCurrencyCode,
			pairLabel,
			loadMarginRiskStatus,
			loadLastMarginLvl,
			currentCurrencyInterestRate,
			loadMarginCurrency,
		},
		tickers: { filter },
		global: { isAuthenticated },
		finance: { loadMarginOptions },
		history,
		render,
	} = useMst()
	const { formatMessage } = useIntl()
	const [isOnSubmitLoading, setIsOnSubmitLoading] = useState<boolean>(false)
	const [isOpenTransferMargin, setIsOpenTransferMargin] = useState<boolean>(false)
	const [isOpenMarginOrderModal, setIsOpenMarginOrderModal] = useState<boolean>(false)
	const [orderItem, setOrderItem] = useState<any>({})
	const [errors, setErrors] = useState<ErrorsState>({})
	const sellPrice = orderBook.getFirst(true)
	const buyPrice = orderBook.getFirst(false)

	// Determine if the selected trade type is margin or spot
	const isMargin = useMemo(() => filter.tradeType === TradeTypeEnum.CROSS, [filter.tradeType])

	useEffect(() => {
		if (isAuthenticated && isMargin && render.margin) {
			loadMarginCurrency(ACCOUNT_TYPE[AccountTypeEnum.CROSS_MARGIN]).then(() => null)
			loadMarginOptions().then(() => null)
		}

		if (isAuthenticated && render.margin) {
			loadMarginStatus({
				[queryVars.wallet_type]: ACCOUNT_TYPE[AccountTypeEnum.CROSS_MARGIN],
				[queryVars.pair]: undefined,
			}).then(() => null)
		}
	}, [isAuthenticated, isMargin, pair, render.margin])

	const closeMarginOrderModalAction = () => {
		setIsOpenMarginOrderModal(false)
		setOrderItem({})
	}

	const openMarginOrderModalAction = (order: any) => {
		setOrderItem(order)
		setIsOpenMarginOrderModal(true)
	}

	// Memoize some values for performance optimization
	const { amountPrecision, pricePrecision, getCurrencyCodeBySide } = useMemo(() => {
		if (pair) {
			return {
				amountPrecision: pair.amount_precision ?? 6,
				pricePrecision: pair.price_precision ?? 6,
				getCurrencyCodeBySide: pair.getCurrencyCodeBySide,
			}
		}

		return {
			amountPrecision: 6,
			pricePrecision: 6,
			getCurrencyCodeBySide: undefined,
		}
	}, [pair])

	const maxBuyingAmount = useMemo(() => {
		if (pair && currentBalance && recentTrade?.price) {
			return formatNumber(
				currentBalance.available === 0 ? 0 : currentBalance.available / recentTrade.price,
				{
					useGrouping: false,
					minimumFractionDigits: amountPrecision ?? 8,
					maximumFractionDigits: amountPrecision ?? 8,
				},
			)
		}

		return null
	}, [pair, currentBalance?.available, recentTrade?.price, amountPrecision])

	const maxSellingAmount = useMemo(() => {
		if (pair && currentBalance && recentTrade?.price) {
			return formatNumber(currentBalance.available * recentTrade.price, {
				useGrouping: false,
				minimumFractionDigits: pricePrecision ?? 8,
				maximumFractionDigits: pricePrecision ?? 8,
			})
		}

		return null
	}, [pair, currentBalance?.available, pricePrecision, recentTrade?.price])

	// Format spot/margin balance for display
	const mainBalance = useMemo(
		() =>
			isAuthenticated && currentBalance
				? formatNumber(currentBalance.available, {
						useGrouping: false,
						minimumFractionDigits: currentBalance?.precision ?? 8,
						maximumFractionDigits: currentBalance?.precision ?? 8,
				  })
				: undefined,
		[isAuthenticated, currentBalance?.available],
	)

	// Determine the currency code for the selected trade action
	const currencyCodeBySide = useMemo(
		() => (getCurrencyCodeBySide ? getCurrencyCodeBySide(filter.tradeAction) : ""),
		[filter.tradeAction, getCurrencyCodeBySide],
	)

	// Handle changes to the order price input
	const onChangeOrderPrice = useCallback(
		(value: string) => {
			const newValue = formatStringToNumber(value)

			if (value === "") {
				tradeForm.setOrderPrice(value)

				return "0"
			}

			if (!Number.isNaN(newValue)) {
				tradeForm.setOrderPrice(value)

				if (filter.tradeAction === TradeActionsEnum.SELL && mainBalance) {
					const newAmount = formatStringToNumber(tradeForm.orderQtyString)
					const newOrderValue = formatNumberNoRounding(newAmount * newValue || 0, pricePrecision)

					tradeForm.setOrderValue(newOrderValue)
					return newOrderValue
				}

				if (filter.tradeAction === TradeActionsEnum.BUY && mainBalance) {
					const newOrderValue = formatStringToNumber(tradeForm.orderValueString)
					const newAmount = formatNumberNoRounding(
						newOrderValue !== 0 && newValue !== 0 ? newOrderValue / newValue : 0,
						amountPrecision,
					)

					tradeForm.setOrderQty(newAmount)
					return newAmount
				}
			}

			return "0"
		},
		[tradeForm.orderQtyString, pricePrecision, filter.tradeAction, mainBalance, amountPrecision],
	)

	// Handle changes to the target price input
	const onChangeTargetPrice = useCallback(
		(value: string) => {
			const newValue = formatStringToNumber(value)

			if (!Number.isNaN(newValue)) {
				tradeForm.setTriggerPrice(value)

				return newValue
			}

			return "0"
		},
		[tradeForm.orderQtyString, pricePrecision],
	)

	// Handle blur events on the target price input
	const onBlurTargetPrice = useCallback(
		(value: string) => {
			const newValue = formatStringToNumber(value)

			if (!Number.isNaN(newValue)) {
				const newOrderPrice = formatNumberNoRounding(newValue, pricePrecision)

				tradeForm.setTriggerPrice(newOrderPrice)

				return newOrderPrice
			}

			return "0"
		},
		[pricePrecision],
	)

	// Handle blur events on the order price input
	const onBlurOrderPrice = useCallback(
		(value: string) => {
			const newValue = formatStringToNumber(value)

			if (!Number.isNaN(newValue)) {
				const newOrderPrice = formatNumberNoRounding(newValue, pricePrecision)

				tradeForm.setOrderPrice(newOrderPrice)

				return newOrderPrice
			}

			return "0"
		},
		[pricePrecision],
	)

	// Handle changes to the order value input
	const onChangeOrderValue = useCallback(
		(value: string) => {
			const newValue = formatStringToNumber(value)

			if (value === "") {
				tradeForm.setOrderValue("")
				tradeForm.setOrderQty("0")

				return "0"
			}

			if (!Number.isNaN(newValue)) {
				tradeForm.setOrderValue(value)

				const newPrice = formatStringToNumber(tradeForm.orderPriceString)
				const newOrderQty =
					newValue !== 0 && newPrice > 0
						? formatNumberNoRounding(newValue / newPrice, amountPrecision)
						: "0"

				tradeForm.setOrderQty(newOrderQty)

				if (filter.tradeAction === TradeActionsEnum.SELL && mainBalance) {
					const result =
						newOrderQty !== "0"
							? (formatStringToNumber(newOrderQty) / formatStringToNumber(mainBalance)) * 100
							: 0

					tradeForm.setRangePercentage(result >= 100 ? 100 : result)
				}

				if (filter.tradeAction === TradeActionsEnum.BUY && mainBalance) {
					const result = newValue !== 0 ? (newValue / formatStringToNumber(mainBalance)) * 100 : 0

					tradeForm.setRangePercentage(result >= 100 ? 100 : result)
				}

				return newOrderQty
			}

			return "0"
		},
		[tradeForm.orderPriceString, amountPrecision, filter.tradeAction, mainBalance],
	)

	// Handle blur events on the order value input
	const onBlurOrderValue = useCallback(
		(value: string) => {
			const newValue = formatStringToNumber(value)

			if (!Number.isNaN(newValue) && newValue !== 0) {
				const newOrderValue = formatNumberNoRounding(newValue, pricePrecision)

				tradeForm.setOrderValue(newOrderValue)

				return newOrderValue
			}

			tradeForm.setOrderValue("0")
			return "0"
		},
		[pricePrecision],
	)

	// Handle changes to the order quantity input
	const onChangeOrderQty = useCallback(
		(value: string) => {
			const newValue = formatStringToNumber(value)

			if (value === "") {
				tradeForm.setOrderQty("")

				tradeForm.setOrderValue("0")
				tradeForm.setRangePercentage(0)
				return "0"
			}

			if (!Number.isNaN(newValue)) {
				tradeForm.setOrderQty(value)

				const price = formatStringToNumber(tradeForm.orderPriceString)
				const newOrderValue =
					newValue * price === 0 ? "0" : formatNumberNoRounding(newValue * price, pricePrecision)

				tradeForm.setOrderValue(newOrderValue)

				if (filter.tradeAction === TradeActionsEnum.SELL && mainBalance) {
					const result = newValue !== 0 ? (newValue / formatStringToNumber(mainBalance)) * 100 : 0

					tradeForm.setRangePercentage(result >= 100 ? 100 : result)
				}

				if (filter.tradeAction === TradeActionsEnum.BUY && mainBalance) {
					const result =
						formatStringToNumber(newOrderValue) !== 0
							? (formatStringToNumber(newOrderValue) / formatStringToNumber(mainBalance)) * 100
							: 0

					tradeForm.setRangePercentage(result >= 100 ? 100 : result)
				}

				return newOrderValue
			}

			return "0"
		},
		[tradeForm.orderPriceString, pricePrecision, filter.tradeAction, mainBalance],
	)

	// Handle blur events on the order quantity input
	const onBlurOrderQty = useCallback(
		(value: string) => {
			const newValue = formatStringToNumber(value)

			if (!Number.isNaN(newValue) && newValue !== 0) {
				const newOrderQty = formatNumberNoRounding(newValue, amountPrecision)

				tradeForm.setOrderQty(newOrderQty)

				return newOrderQty
			}

			tradeForm.setOrderQty("0")
			return "0"
		},
		[amountPrecision],
	)

	// Handle changes to the range percentage slider
	const onChangeRangePercentage = useCallback(
		(val: number[]) => {
			const percent = val[0]

			tradeForm.setRangePercentage(percent)

			if (filter.tradeAction === TradeActionsEnum.BUY && mainBalance) {
				const mainBalanceValue = formatStringToNumber(mainBalance)
				const newOrderValue =
					(percent / 100) * mainBalanceValue !== 0
						? formatNumberNoRounding((percent / 100) * mainBalanceValue, pricePrecision)
						: "0"

				tradeForm.setOrderValue(newOrderValue)

				if (
					filter.tradeMode === OrderTypeEnum.LIMIT ||
					filter.tradeMode === OrderTypeEnum.STOP_ORDER
				) {
					const newPrice = formatStringToNumber(tradeForm.orderPriceString)
					const newOrderQty =
						formatStringToNumber(newOrderValue) / newPrice !== 0 && newPrice !== 0
							? formatNumberNoRounding(
									formatStringToNumber(newOrderValue) / newPrice,
									amountPrecision,
							  )
							: formatNumberNoRounding(0, amountPrecision)

					tradeForm.setOrderQty(newOrderQty)
				}
			}

			if (filter.tradeAction === TradeActionsEnum.SELL && mainBalance) {
				const mainBalanceValue = formatStringToNumber(mainBalance)
				const newOrderQty = formatNumberNoRounding(
					(percent / 100) * mainBalanceValue,
					amountPrecision,
				)

				tradeForm.setOrderQty(newOrderQty)

				if (
					filter.tradeMode === OrderTypeEnum.LIMIT ||
					filter.tradeMode === OrderTypeEnum.STOP_ORDER
				) {
					const price = formatStringToNumber(tradeForm.orderPriceString)
					const newOrderValue = formatNumberNoRounding(
						formatStringToNumber(newOrderQty) * price,
						pricePrecision,
					)

					tradeForm.setOrderValue(newOrderValue)
				}
			}
		},
		[
			filter.tradeAction,
			mainBalance,
			pricePrecision,
			amountPrecision,
			tradeForm.orderPriceString,
			filter.tradeMode,
		],
	)

	const recentTradeAction = useCallback(
		(percent: number) => {
			if (filter.tradeAction === TradeActionsEnum.BUY) {
				const result = recentTrade?.price
					? recentTrade.price + (recentTrade.price * percent) / 100
					: 0

				onChangeOrderPrice(formatNumberNoRounding(result, pricePrecision))
			} else {
				const result = recentTrade?.price
					? recentTrade.price - (recentTrade.price * percent) / 100
					: 0

				onChangeOrderPrice(formatNumberNoRounding(result, pricePrecision))
			}
		},
		[recentTrade, filter.tradeAction, onChangeOrderPrice, pricePrecision],
	)

	const bidTradeAction = useCallback(() => {
		if (buyPrice) onChangeOrderPrice(formatNumberNoRounding(buyPrice, pricePrecision))
	}, [buyPrice, pricePrecision, onChangeOrderPrice])

	const askTradeAction = useCallback(() => {
		if (sellPrice) onChangeOrderPrice(formatNumberNoRounding(sellPrice, pricePrecision))
	}, [sellPrice, pricePrecision, onChangeOrderPrice])

	const submitOrder = useCallback(
		async (order: any) => {
			if (filter.tradeMode === OrderTypeEnum.STOP_ORDER) {
				if (!tradeForm.isOpenStopOrder) {
					tradeForm.setOrder(order)
				}
			} else {
				setIsOnSubmitLoading(true)

				try {
					await ExchangeService.createOrder(order)

					onBlurOrderQty("0")
					onBlurOrderValue("0")
					onChangeRangePercentage([0])

					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (err: any) {
					const catchErrors = formatErrorFromServer(err.data)

					if (
						catchErrors.serverErrors?.non_field_errors &&
						Array.isArray(catchErrors.serverErrors?.non_field_errors)
					) {
						catchErrors.serverErrors?.non_field_errors.forEach(element => {
							toast(element, {
								position: "bottom-left",
								autoClose: 5000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
								theme: "dark",
							})
						})
					}

					if (catchErrors.serverErrors?.total && Array.isArray(catchErrors.serverErrors?.total)) {
						catchErrors.serverErrors?.total.forEach(element => {
							toast(element, {
								position: "bottom-left",
								autoClose: 5000,
								hideProgressBar: false,
								closeOnClick: true,
								pauseOnHover: true,
								draggable: true,
								progress: undefined,
								theme: "dark",
							})
						})
					}
				} finally {
					setIsOnSubmitLoading(false)
				}
			}
		},
		[
			filter.tradeMode,
			tradeForm.isOpenStopOrder,
			setIsOnSubmitLoading,
			onBlurOrderQty,
			onBlurOrderValue,
			onChangeRangePercentage,
		],
	)

	const onSubmit: () => Promise<boolean> = useCallback(async () => {
		if (!isOnSubmitLoading && pair) {
			const order: ICreateOrderBody = {
				type: ORDER_TYPE[filter.tradeMode].toString(),
				side: ORDER_SIDE[filter.tradeAction].toString(),
				symbol: pair.symbol,
				amount: tradeForm.orderQtyString,
				price: tradeForm.orderPriceString,
				pair: pair.symbol,
				wallet_type: ACCOUNT_TYPE[accountType].toString(),
			}

			if (filter.tradeMode === OrderTypeEnum.STOP_ORDER) {
				order.stop_operator =
					pair.close > formatStringToNumber(tradeForm.triggerPriceString) ? "2" : "1"
				order.stop_price = tradeForm.triggerPriceString
			}

			if (filter.tradeMode === OrderTypeEnum.MARKET) {
				delete order.price

				if (filter.tradeAction === TradeActionsEnum.BUY) {
					order.quote_amount = tradeForm.orderValueString
					delete order.amount
				}
			}

			const { isValid, errors: nextErrors } = onSubmitValidate(
				order,
				pair.minimum_order_size,
				pair.maximum_order_size,
				formatMessage,
				amountPrecision,
				pricePrecision,
				filter.tradeMode === OrderTypeEnum.MARKET && filter.tradeAction === TradeActionsEnum.BUY,
			)

			setErrors(nextErrors)

			if (isValid && !isMargin) {
				await submitOrder(order)
			}

			if (isValid && isMargin) {
				openMarginOrderModalAction(order)
			}

			return false
		}

		return false
	}, [
		history.currentOpenTab,
		isAuthenticated,
		isOnSubmitLoading,
		pair,
		filter.tradeMode,
		filter.tradeAction,
		accountType,
		formatMessage,
		amountPrecision,
		pricePrecision,
		tradeForm.orderQtyString,
		tradeForm.orderPriceString,
		tradeForm.triggerPriceString,
		tradeForm.orderValueString,
		tradeForm.isOpenStopOrder,
		submitOrder,
	])

	const submitActionMarginModal = useCallback(
		() =>
			submitOrder(orderItem).finally(() => {
				closeMarginOrderModalAction()
				loadBalances().then(() => null)
			}),
		[orderItem, submitOrder, closeMarginOrderModalAction, loadBalances],
	)

	const onSubmitAction = useCallback(async () => {
		if (isOnSubmitLoading) {
			return
		}

		await onSubmit()
	}, [isMargin, onSubmit, isOnSubmitLoading])

	const onSubmitMarginTransfer = useCallback(loadBalances, [loadBalances])

	// Set the order price to the recent trade price when the component mounts
	useEffect(() => {
		if (isLoaded) {
			tradeForm.setOrderPrice(
				recentTrade?.price ? formatNumberNoRounding(recentTrade?.price, pricePrecision) : "0",
			)

			tradeForm.setTriggerPrice(
				recentTrade?.price ? formatNumberNoRounding(recentTrade?.price, pricePrecision) : "0",
			)
		}
	}, [pricePrecision, isLoaded])

	useEffect(() => {
		if (isAuthenticated && render.margin) loadLastMarginLvl().then(() => null)
	}, [isAuthenticated, render.margin])

	// Reset values if we change tradeAction, tradeMode
	useEffect(() => {
		if (amountPrecision) {
			tradeForm.setOrderQty("0")
		}

		if (pricePrecision) {
			tradeForm.setOrderValue("0")
		}

		tradeForm.setRangePercentage(0)
	}, [
		filter.tradeAction,
		filter.tradeMode,
		amountPrecision,
		pricePrecision,
		tradeForm,
		accountType,
	])

	useEffect(() => {
		if (tradeForm.clickedOrder && isAuthenticated) {
			const price = formatNumberNoRounding(tradeForm.clickedOrder.price, pricePrecision)

			if (!currentBalance) return

			const value = `${
				+tradeForm.clickedOrder.price * +tradeForm.clickedOrder.orderDepth >
				currentBalance.available
					? currentBalance.available
					: tradeForm.clickedOrder.price * +tradeForm.clickedOrder.orderDepth
			}`

			if (filter.tradeAction === TradeActionsEnum.SELL) {
				onChangeOrderQty(value)
				onBlurOrderQty(value)
			} else {
				onChangeOrderValue(value)
				onBlurOrderValue(value)
			}

			tradeForm.setOrderPrice(price)

			tradeForm.setClickedOrder(null)
		}
	}, [
		tradeForm.clickedOrder,
		pricePrecision,
		// amountPrecision,
		// filter.tradeAction,
		// currentBalance?.available,
		isAuthenticated,
	])

	useEffect(() => {
		if (Object.keys(errors).length !== 0) {
			setErrors({})
		}
	}, [filter.tradeAction, filter.tradeMode])

	useEffect(() => {
		if (isAuthenticated && isMargin && render.margin) {
			loadMarginRiskStatus().then(() => null)
		}
	}, [isAuthenticated, isMargin, render.margin])

	return {
		// order price block
		orderPriceString: tradeForm.orderPriceString,
		onChangeOrderPrice,
		onBlurOrderPrice,
		// end order price block

		// order value block
		orderValueString: tradeForm.orderValueString,
		onChangeOrderValue,
		onBlurOrderValue,
		// end order value block

		// order qty block
		orderQtyString: tradeForm.orderQtyString,
		onChangeOrderQty,
		onBlurOrderQty,
		// end order qty block

		rangePercentage: tradeForm.rangePercentage,
		onChangeRangePercentage,

		triggerPriceString: tradeForm.triggerPriceString,
		onBlurTargetPrice,

		tradeType: filter.tradeType,
		tradeAction: filter.tradeAction,
		setTradeAction: filter.setTradeAction,
		setTradeType: filter.setTradeType,

		setTradeMode: filter.setTradeMode,
		tradeMode: filter.tradeMode,
		isMargin,

		currencyCodeBySide,
		mainBalance,

		maxBuyingAmount,
		maxSellingAmount,
		getCurrencyCodeBySide,

		onChangeTargetPrice,
		isOnSubmitLoading,
		onSubmit: onSubmitAction,
		baseCurrencyCode,
		quoteCurrencyCode,
		isAuthenticated,

		recentTradeAction,
		bidTradeAction,
		askTradeAction,

		// error block
		orderPriceError: "price" in errors ? errors.price : "",
		qtyError: "amount" in errors ? errors.amount : "",
		quoteQtyError: "quote_amount" in errors ? errors.quote_amount : "",
		stopPriceError: "stop_price" in errors ? errors.stop_price : "",
		// end error block

		isLoaded,
		marginLvl: tradeForm.marginLvl,
		setOpenMarginModal: tradeForm.setOpenMarginChangeModal,
		balancesCrossBorrowsArr: balancesCrossBorrowsTerminal,
		pairLabel,
		isOpenTransferMargin,
		setIsOpenTransferMargin,
		toggleCloseTransferMarginModal: () => {
			setIsOpenTransferMargin(false)
		},
		toggleOpenTransferMarginModal: () => {
			setIsOpenTransferMargin(true)
		},
		onSubmitMarginTransfer,
		isOpenMarginOrderModal,
		closeMarginOrderModalAction,
		dailyInterestRate: formatNumber(Number(currentCurrencyInterestRate) * 100, {
			useGrouping: false,
			minimumFractionDigits: 2,
			maximumFractionDigits: 10,
		}),
		submitActionMarginModal,
		maintenanceMargin,
		initialMargin,
	}
}

export default useTradingForm
