import React, { useCallback, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";

import { FavoriteFilterTypeEnum } from "types/exchange";
import styles from "../index.module.scss";

const Favorites: React.FC<{
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	item: any;
	handleLinkClick: (name: string) => void;
	favoriteFilterType: FavoriteFilterTypeEnum;
}> = ({ item, handleLinkClick, favoriteFilterType }) => {
	const { formatNumber } = useIntl();
	const fixedPercent = +item.change_percent.toFixed(2);
	const changePercent = fixedPercent < 0.01 && fixedPercent > -0.01 ? 0 : fixedPercent;
	const changePercentText = useMemo(
		() =>
			`${changePercent > 0 ? "+" : ""}${formatNumber(changePercent, {
				useGrouping: false,
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			})}%`,
		[changePercent, formatNumber],
	);

	const changeValueText = useMemo(
		() =>
			`${formatNumber(item.close, {
				useGrouping: true,
				minimumFractionDigits: 2,
				maximumFractionDigits: item.price_precision,
			})}`,
		[item.change, formatNumber],
	);
	const changePercentValueText = useMemo(
		() => `${changePercentText} / ${changeValueText}`,
		[changePercentText, changeValueText],
	);

	const onClickLink = useCallback(() => handleLinkClick(item.symbol), [item.symbol]);

	return (
		<li className={styles.favorites__item}>
			<button type="button" onClick={onClickLink}>
				<span className={styles.favorites__name}>{item.label}</span>
				<span
					className={`${styles.favorites__count} ${
						changePercent > 0 ? styles.favorites__count___green : ""
					} ${changePercent < 0 ? styles.favorites__count___red : ""}`}
				>
					{favoriteFilterType === FavoriteFilterTypeEnum.PERCENT ? changePercentText : null}

					{favoriteFilterType === FavoriteFilterTypeEnum.VALUE ? changeValueText : null}

					{favoriteFilterType === FavoriteFilterTypeEnum.PERCENT_VALUE
						? changePercentValueText
						: null}
				</span>
			</button>
		</li>
	);
};

export default observer(Favorites);
