import React, { memo } from "react"

const Support247 = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="none" viewBox="0 0 30 30">
		<path
			fill="#B2B7C6"
			d="M25.518 4.413A14.911 14.911 0 006.57 2.563a14.95 14.95 0 00-5.967 8.182 14.971 14.971 0 00.612 10.114c.143.297.19.632.135.957L.036 28.14a1.497 1.497 0 00.403 1.36 1.492 1.492 0 001.09.434h.298l6.39-1.286c.324-.039.654.008.955.135a14.908 14.908 0 0010.099.613 14.93 14.93 0 008.17-5.976 14.968 14.968 0 00-1.848-18.977l-.075-.03zM8.994 16.48a1.491 1.491 0 01-1.38-.923 1.497 1.497 0 011.089-2.039 1.49 1.49 0 011.532.636 1.496 1.496 0 01-1.241 2.326zm5.971 0a1.491 1.491 0 01-1.464-1.787 1.496 1.496 0 011.173-1.175 1.49 1.49 0 011.533.636 1.497 1.497 0 01-1.242 2.326zm5.972 0a1.491 1.491 0 01-1.38-.923 1.497 1.497 0 011.088-2.039 1.49 1.49 0 011.533.636 1.497 1.497 0 01-1.241 2.326z"
		/>
	</svg>
)

export default memo(Support247)
