import React, { FC } from "react"

import ArrowLeft from "assets/icons/aboutUs/ArrowLeft"
import ArrowRight from "assets/icons/aboutUs/ArrowRight"
import styles from "../index.module.scss"

const NextArrow: FC<{ onClick?: () => void }> = ({ onClick }) => (
	<div className={`${styles.arrow} ${styles.next}`} onClick={onClick}>
		<ArrowRight />
	</div>
)

const PrevArrow: FC<{ onClick?: () => void }> = ({ onClick }) => (
	<div className={`${styles.arrow} ${styles.prev}`} onClick={onClick}>
		<ArrowLeft />
	</div>
)

export { NextArrow, PrevArrow }
