import React, { memo } from "react"

const NavArrowRight = () => (
	<svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.91 4.41a.833.833 0 011.179 0l5 5a.833.833 0 010 1.18l-5 5a.833.833 0 11-1.179-1.18L11.32 10 6.91 5.59a.833.833 0 010-1.18z"
			fill="#7c59ff"
		/>
	</svg>
)

export default memo(NavArrowRight)
