import React, { memo } from "react"

const Security360 = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="27" height="30" fill="none" viewBox="0 0 27 30">
		<path
			fill="#B2B7C6"
			d="M14.181.157a.922.922 0 00-1.029 0 27.793 27.793 0 01-11.689 4.54.926.926 0 00-.57.313.943.943 0 00-.226.614v8.438c0 7.295 4.284 12.618 12.665 15.875a.92.92 0 00.669 0c8.381-3.257 12.666-8.582 12.666-15.875V5.624a.943.943 0 00-.228-.614.926.926 0 00-.57-.314A27.793 27.793 0 0114.18.157zm6.643 10.819l-7.429 7.5a.928.928 0 01-1.013.203.928.928 0 01-.301-.203l-3.715-3.75a.943.943 0 010-1.328.925.925 0 011.315 0l3.057 3.088 6.771-6.838a.929.929 0 011.014-.203.929.929 0 01.503.508.947.947 0 01-.202 1.023z"
		/>
	</svg>
)

export default memo(Security360)
